let initialState = {
	basket: [], //nodes that are selected
	data: [],
	cachedVis: {}, //depth for cachedVis,
	depth: 1,
	similarityMatrix: [],
	avgSim: 0,
	selectedMemberDocs: [],
	totalDocCount: 0
};

const cluster_vis = (state = initialState, action) => {
	switch (action.type) {
		case 'ADD_NODE_TO_BASKET': {
			const newBasket = Array.from(state.basket);
			if (typeof +action.payload === 'number' || newBasket.indexOf(+action.payload) !== -1) {
				newBasket.push(+action.payload);
				const newState = Object.assign({}, { ...state }, { basket: newBasket });
				return newState;
			} else {
				return state;
			}
		}
		case 'REMOVE_NODE_FROM_BASKET': {
			if (typeof +action.payload === 'number') {
				const index = state.basket.indexOf(+action.payload);
				const newBasket = Array.from([
					...state.basket.slice(0, index),
					...state.basket.slice(index + 1)
				]);
				if (newBasket.length === 0) {
					const newState = Object.assign(
						{},
						{ ...state },
						{ selectedMemberDocs: [], basket: newBasket }
					);
					return newState;
				} else {
					const newState = Object.assign({}, { ...state }, { basket: newBasket });
					return newState;
				}
			} else {
				return state;
			}
		}
		case 'SET_MEMBER_DOCS': {
			const mut = action.payload.data;
			return Object.assign({}, { ...state }, { selectedMemberDocs: mut });
		}
		case 'CLEAR_MEMBER_DOCS': {
			return Object.assign({}, { ...state }, { selectedMemberDocs: [] });
		}
		case 'SET_DATA': {
			const mut = action.payload.data;
			return Object.assign({}, { ...state }, { data: mut });
		}
		case 'CLEAR_DATA': {
			return Object.assign({}, { ...state }, { data: [] });
		}
		case 'SET_DOC_COUNT': {
			const mut = action.payload.data;
			return Object.assign({}, { ...state }, { totalDocCount: mut });
		}
		case 'CLEAR_DOC_COUNT': {
			return Object.assign({}, { ...state }, { totalDocCount: 0 });
		}
		case 'CLEAR_BASKET': {
			return Object.assign({}, { ...state }, { basket: [] });
		}
		case 'CACHE_VIS': {
			const cached = { ...state.cachedVis };
			cached[state.depth] = action.payload;
			return Object.assign({}, { ...state }, { cachedVis: cached });
		}
		case 'SWAP_CACHED_VIS': {
			const cached = { ...state.cachedVis };
			cached[state.depth] = action.payload;
			//taking the value of cached[current_depth] and assigning it to basket to recluster
			return Object.assign({}, { ...state }, { cachedVis: cached });
		}
		case 'CLEAR_CACHE_VIS': {
			return Object.assign({}, { ...state }, { cachedVis: [] });
		}
		case 'DEPTH_UP': {
			//do not want to manipulate state directly so i'm using mut instead of state.depth
			const mut = state.depth + 1;
			return Object.assign({}, { ...state }, { depth: mut });
		}
		case 'DEPTH_DOWN': {
			const mut = Math.max(state.depth - 1, 0);
			if (mut > 0) {
				return Object.assign({}, { ...state }, { depth: mut });
			} else return state;
		}
		case 'CLEAR_DEPTH': {
			return Object.assign({}, { ...state }, { depth: 1 });
		}
		case 'UPDATE_SIM_MAT': {
			const simMat = action.payload.data;
			return Object.assign({}, { ...state }, { similarityMatrix: simMat });
		}
		case 'AVERAGE_SIM_MAT': {
			const { basket, similarityMatrix } = state;
			let totalSim = 0;
			let numPairs = 0;
			for (let i = 1; i < basket.length; i++) {
				for (let j = 0; j < i; j++) {
					const first = basket[i];
					const second = basket[j];
					const ind1 = Math.max(first, second);
					const ind2 = Math.min(first, second);
					const sim = similarityMatrix[ind2][ind1];
					totalSim += sim;
					numPairs++;
				}
			}
			const avgSim = totalSim / numPairs;
			if (avgSim && avgSim > 0) {
				return { ...state };
			} else {
				return { ...state };
			}
		}

		default:
			return state;
	}
};

export default cluster_vis;
