import { theme } from 'cymantic-ui/dist/theme';
import { css } from '@emotion/css';

import { horizontalPadding } from './LeftSidebar.definitions';

const leftSidebarLinkBase = css`
	cursor: pointer;
	align-items: center;
	display: flex;
	font-size: ${theme.fontSize.bodyXS};
	gap: ${theme.space.space8};
	padding: ${theme.space.space8} ${horizontalPadding};
`;

const leftSidebarLinkActive = css`
	background-color: ${theme.color.grey200};
	color: ${theme.color.grey900};
`;

export const leftSidebarLink = {
	root: css`
		&:link,
		&:visited,
		&:hover,
		&:active {
			${leftSidebarLinkBase}
			color: ${theme.color.grey700};
		}

		:hover {
			${leftSidebarLinkActive}
		}
	`,
	active: css`
		&:link,
		&:visited,
		&:hover,
		&:active {
			${leftSidebarLinkBase}
			${leftSidebarLinkActive}
		}
	`
};
