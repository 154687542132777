export const setLoading = () => ({
	type: 'SET_LOADING'
});

// Content
export const searchRequest = (data) => ({
	type: 'SEARCH_REQUEST',
	payload: { data }
});

export const searchResults = (data) => ({
	type: 'SEARCH_RESULTS',
	payload: { data }
});

export const toggleSearchPage = (data) => ({
	type: 'TOGGLE_SHOW_RESULTS',
	payload: data
});

export const addNodeToBasket = (data) => ({
	type: 'ADD_NODE_TO_BASKET',
	payload: data
});

export const removeNodeFromBasket = (data) => ({
	type: 'REMOVE_NODE_FROM_BASKET',
	payload: data
});

export const clearBasket = () => ({
	type: 'CLEAR_BASKET'
});

export const cacheClusterVis = (data) => ({
	type: 'CACHE_VIS',
	payload: data
});

export const swapCachedVis = () => ({
	type: 'SWAP_CACHED_VIS'
});

export const clearCacheVis = () => ({
	type: 'CLEAR_CACHE_VIS'
});

export const increaseDepth = () => ({
	type: 'DEPTH_UP',
	payload: {}
});

export const decreaseDepth = () => ({
	type: 'DEPTH_DOWN',
	payload: {}
});

export const clearDepth = () => ({
	type: 'CLEAR_DEPTH'
});

export const setData = (data) => ({
	type: 'SET_DATA',
	payload: { data }
});

export const clearData = () => ({
	type: 'CLEAR_DATA'
});

export const setSelectedMemberDocs = (data) => ({
	type: 'SET_MEMBER_DOCS',
	payload: { data }
});

export const clearSelectedMemberDocs = () => ({
	type: 'CLEAR_MEMBER_DOCS'
});

export const setDocCount = (data) => ({
	type: 'SET_DOC_COUNT',
	payload: { data }
});

export const clearDocCount = () => ({
	type: 'CLEAR_DOC_COUNT'
});

export const toggleShowProfileHover = (data) => ({
	type: 'TOGGLE_SHOW_PROFILE_HOVER',
	payload: data
});

export const setSigningIn = (data) => ({
	type: 'TOGGLE_SIGNING_IN',
	payload: { data }
});

export const setSigningUp = (data) => ({
	type: 'TOGGLE_SIGNING_UP',
	payload: { data }
});

export const setForgotPass = (data) => ({
	type: 'TOGGLE_FORGOT_PASS',
	payload: { data }
});

// Auth
export const setUser = (data) => ({
	type: 'SET_USER',
	payload: { data }
});

export const setUserData = (data) => ({
	type: 'SET_USER_DATA',
	payload: { data }
});

export const setNewUser = (data) => ({
	type: 'SET_NEW_USER',
	payload: { data }
});

export const setJwt = (data) => ({
	type: 'STORE_JWT',
	payload: { data }
});

export const setCognitoJwt = (data) => ({
	type: 'STORE_COGNITO_JWT',
	payload: { data }
});

export const setUserId = (data) => ({
	type: 'SET_USER_ID',
	payload: { data }
});

export const setUserFiles = (data) => ({
	type: 'SET_USER_FILES',
	payload: { data }
});

export const setUserFolders = (data) => ({
	type: 'SET_USER_FOLDERS',
	payload: { data }
});

//similarity
export const updateSimMat = (data) => ({
	type: 'UPDATE_SIM_MAT',
	payload: { data }
});

export const avgSimMat = () => ({
	type: 'AVERAGE_SIM_MAT',
	payload: {}
});

// Databases
export const addDatabases = (data) => ({
	type: 'ADD_DATABASES',
	payload: { data }
});

export const setActiveDatabase = (data) => ({
	type: 'SET_ACTIVE_DATABASE',
	payload: { data }
});

export const removeActiveDatabase = () => ({
	type: 'REMOVE_ACTIVE_DATABASE'
});
