import { css } from '@emotion/css';
import { theme } from 'cymantic-ui/dist/theme';

export const transcriptRow = {
	root: css`
		display: grid;
		grid-template-columns: 75px 150px minmax(150px, 2fr);
		word-break: break-all;
		column-gap: 1rem;
		padding: ${theme.space.space8} 0;

		&:not(:first-child) {
			border-top: 1px solid ${theme.color.grey200};
		}
	`
};

export const transcriptFilePage = {
	info: css`
		display: flex;
		flex-direction: column;
		gap: ${theme.space.space8};
		padding: ${theme.space.space24} ${theme.space.space32};
		border-bottom: 1px solid ${theme.color.grey300};
	`,
	transcript: css`
		padding: ${theme.space.space24} ${theme.space.space32};
		background: ${theme.color.grey50};
	`
};
