// TODO: remove hardcoded code codes (0 = loading, 200 = success, 500 = error)
let initialState = {
	search: {
		code: 0
	}
};

const sub = (state = initialState, action) => {
	switch (action.type) {
		case 'SEARCH_RESULTS': {
			return Object.assign(
				{},
				{ ...state },
				{
					search: action.payload.data
				}
			);
		}
		case 'SET_LOADING': {
			return Object.assign(
				{},
				{ ...state },
				{
					search: {
						...state.search,
						code: 0,
						userMessage: '',
						userMessageTitle: ''
					}
				}
			);
		}
		default: {
			return Object.assign({ ...state });
		}
	}
};

export default sub;
