import { css } from '@emotion/css';

export const container = css({
	display: 'flex',
	flexDirection: 'row',
	height: '100vh',
	width: '100%'
});

export const content = css({
	height: '100%',
	overflow: 'hidden',
	width: '100%'
});
