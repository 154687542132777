import { Navigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { checkIfTokenIsValid, isAuthOff } from '../../components/Auth/CognitoHelpers';

const Protected = ({ children }) => {
	const [isValidToken, setIsValidToken] = useState(true);
	useEffect(() => {
		const validateToken = async () => {
			const isValid = await checkIfTokenIsValid();
			setIsValidToken(isValid);
		};

		if (!isAuthOff()) {
			validateToken();
		}
	}, []);

	if (!isValidToken) {
		return <Navigate to="/" replace />;
	}
	return children;
};

export default Protected;
