import { css } from '@emotion/css';
import { theme } from 'cymantic-ui/dist/theme';

export const pageSection = {
	root: css`
		display: flex;
		flex-direction: column;
		gap: ${theme.space.space16};
	`,
	header: css`
		display: flex;
		flex-direction: column;
		gap: ${theme.space.space4};
	`
};
