import * as React from 'react';
import { AnimatePresence } from 'framer-motion';

import { NodeLabel } from './NodeLabel';
import { TreeNode, Path, PathArr, MutableTreeState } from './Tree.definitions';
import { isNode, Keyboard } from './utils';
import { nodeStyles } from './Tree.styles';

interface INodeProps extends React.LiHTMLAttributes<HTMLLIElement> {
	node: TreeNode;
	treeState: React.MutableRefObject<MutableTreeState>;
	expanded?: boolean;
}

const setRef = <V extends HTMLLIElement>(ref: React.ForwardedRef<V>, value: V) => {
	const refIsFunction = typeof ref === 'function';
	if (ref) {
		if (refIsFunction) {
			ref(value);
		} else {
			// eslint-disable-next-line no-param-reassign
			ref.current = value;
		}
	}
};

const useForkRef = <T extends HTMLLIElement>(ref1: React.Ref<T>, ref2: React.Ref<T>) => {
	return React.useMemo(() => {
		return (value: T) => {
			setRef(ref1, value);
			setRef(ref2, value);
		};
	}, [ref1, ref2]);
};

const MemoNode = React.forwardRef<HTMLLIElement, INodeProps>(
	({ children, node, treeState, onClick, onKeyDown, ...rest }, ref) => {
		const getTree = React.useCallback(() => treeState.current, [treeState]);

		const getSelectedPath = React.useCallback(() => {
			const { selected } = getTree();
			return Array.isArray(selected) ? selected : selected.path;
		}, [getTree]);

		const [isExpanded, setIsExpanded] = React.useState(() => {
			const { expanded } = getTree();
			return expanded.some((path) => Path.equal(path, node.path));
		});

		const [isSelected, setIsSelected] = React.useState(() => {
			const path = getSelectedPath();
			return Path.equal(path, node.path);
		});

		const treeItem = React.useRef<HTMLLIElement>(null);
		const setTreeItem = useForkRef(treeItem, ref);

		React.useEffect(() => {
			// Synchronize state between component and data structure
			if (isExpanded !== node.expanded()) {
				if (node.expanded()) {
					node.collapse();
				} else {
					node.expand();
				}
			}

			if (isSelected !== node.selected()) {
				if (node.selected()) {
					node.deselect();
				} else {
					node.select();
				}
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		const removePath = <T extends PathArr>(collection: T[], item: T) => {
			return collection.filter((path) => !Path.equal(item, path));
		};

		const tree = getTree();
		const { type } = node;

		React.useEffect(() => {
			node.on('select', () => {
				if (!Array.isArray(tree.selected)) {
					tree.selected.deselect();
				}

				if ((isNode(type) && !node.expanded()) || !isNode(type)) {
					node.parent?.selectin();
				}

				tree.selected = node;
				if (treeItem.current) {
					treeItem.current.focus();
				}
				setIsSelected(true);
			})

				.on('deselect', () => {
					node.parent?.selectout();
					setIsSelected(false);
				})

				.on('expand', () => {
					tree.expanded = removePath(tree.expanded, node.path).concat([node.path]);

					if (isNode(type) && node.parent?.selectedin()) node.parent.selectout();

					setIsExpanded(true);
				})

				.on('collapse', () => {
					tree.expanded = removePath(tree.expanded, node.path);
					node.children.clear();

					if (!node.parent?.selectedin()) node.parent?.selectin();

					setIsExpanded(false);
				});

			const indexOfNodeInParentStack = Path.end(node.path);

			if (indexOfNodeInParentStack && node.parent) {
				node.parent.children.remove(indexOfNodeInParentStack);
			}

			if (node.parent) {
				node.parent.children.add(node);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [node]);

		const ariaAttribute: keyof React.AriaAttributes = isNode(type)
			? 'aria-expanded'
			: 'aria-selected';
		const aria: React.AriaAttributes = { [ariaAttribute]: isExpanded };
		// const pathActive = isSelected || isSelectedIn;

		const handleItemClick: React.MouseEventHandler<HTMLLIElement> = (evt) => {
			node.select();

			if (isNode(type)) {
				if (node.expanded()) {
					node.collapse();
				} else {
					node.expand();
				}
			} else {
				node.expand();
			}

			// TODO: Look at how to remove this? stopPropagation can cause issues
			evt.stopPropagation();
			onClick?.(evt);
		};

		const handleKeydown: React.KeyboardEventHandler<HTMLLIElement> = (evt) => {
			const e = evt as unknown as KeyboardEvent;

			Keyboard.handleArrowDown(e, node);
			Keyboard.handleArrowUp(e, node);
			Keyboard.handleArrowLeft(e, node);
			Keyboard.handleArrowRight(e, node);
			Keyboard.handleEnter(e, node);
			Keyboard.handleHome(e, node);
			Keyboard.handleEnd(e, node);
			Keyboard.handleAsterisk(e, node);

			// TODO: Look at how to remove this? stopPropagation can cause issues
			evt.stopPropagation();
			onKeyDown?.(evt);
		};

		return (
			<li
				className={nodeStyles.node}
				tabIndex={isSelected ? 0 : -1}
				// TODO: Check on this, may be correct?
				// eslint-disable-next-line jsx-a11y/role-has-required-aria-props
				role="treeitem"
				ref={setTreeItem}
				onKeyDown={handleKeydown}
				onClick={handleItemClick}
				{...aria}
				{...rest}
			>
				<NodeLabel
					type={node.type}
					id={node.id}
					to={node.name === 'Library' ? '/library' : `/library/folder/${node.id}`}
					name={node.name}
					insetFactor={node.path.length}
					open={isExpanded}
				/>
				<AnimatePresence>{isExpanded && children}</AnimatePresence>
			</li>
		);
	}
);

export const Node = React.memo(MemoNode);
