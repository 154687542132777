import * as React from 'react';
import { rootStyles } from './Tree.styles';

export type RootProps = React.OlHTMLAttributes<HTMLUListElement>;

export const RootNode = React.forwardRef<HTMLUListElement, RootProps>(
	({ children, ...rest }, ref) => (
		<ul className={rootStyles.root} ref={ref} role="tree" {...rest}>
			{children}
		</ul>
	)
);
