import { Navigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import { LibraryTransition } from '../LibraryTransition';
import { useClientQuery } from '../../../hooks/use-client-query';

import { LibraryLayout, LibraryLayoutHeader } from '../LibraryLayout';
import { LibraryPageError } from '../LibraryPageError';

import { FolderInfo } from '../Library.definitions';
import { LibraryPageEmpty, LibraryPageSkeleton, LibraryPageContent } from './LibraryPageStates';

/**
 * Library states:
 * 1. @isLoading
 * 2. @isEmpty Loads "No Shannon Transcripts" page
 * 3. @hasError (all errors except isEmpty): Loads full page error
 * 4. @hasData Loads page
 * 5. @isUnauthorized Redirects to signout/expired
 */
const LibraryPage = () => {
	const { data, statusObj } = useClientQuery<string, FolderInfo>('getFolderInfo', '');

	// Page states
	const { isLoading, isSuccess, isError, isUnreachable, isUnauthorized } = statusObj;
	const hasError = isError || isUnreachable;
	const hasData = isSuccess && !!data && (!!data?.subFolders.length || !!data?.fileCount);
	const isEmpty = isSuccess && !data?.subFolders.length && !data?.fileCount;

	return (
		<LibraryLayout>
			{isUnauthorized && <Navigate to="/signout/expired" />}

			<LibraryLayoutHeader title="Library" />

			<AnimatePresence>
				{isLoading && (
					<LibraryTransition key="librarySkeleton" hasHeader>
						<LibraryPageSkeleton />
					</LibraryTransition>
				)}
				{isEmpty && (
					<LibraryTransition key="libraryEmpty" delay={0.3} hasHeader>
						<LibraryPageEmpty />
					</LibraryTransition>
				)}
				{hasError && (
					<LibraryTransition key="libraryError" delay={0.3} hasHeader>
						<LibraryPageError />
					</LibraryTransition>
				)}
				{hasData && (
					<LibraryTransition key="libraryContent" delay={0.3} hasHeader>
						<LibraryPageContent data={data} />
					</LibraryTransition>
				)}
			</AnimatePresence>
		</LibraryLayout>
	);
};

export default LibraryPage;
