import * as d3 from 'd3';
import * as d3Hierarchy from 'd3-hierarchy';
import { svgGradientIds } from './ClusterSVGSprites';
import { theme } from 'cymantic-ui/dist/theme';

import {
	handleBubbleMouseMove,
	handleBubbleMouseEnter,
	handleBubbleMouseOut,
	handleBubbleClick
} from './ClusterViewHandlers';

import { createD3BubbleScaler, getMinMaxArrValues } from './ClusterViewHelpers';

import * as settings from './ClusterView.definitions';

/**
 * renderClusterView: main ClusterView renderer
 * @param {*} data
 * @param {*} svgRef
 * @param {*} addNodeToBasket
 * @param {*} removeNodeFromBasket
 * @param {*} query
 */
export const renderClusterView = (data, svgRef, query) => {
	// Set up SVG
	const { svg } = createD3SVG(svgRef);

	// Formatting and scaling data
	const { minSize, maxSize } = getMinMaxArrValues(data.map((n) => n.sizeValue));
	const { getBubbleScale } = createD3BubbleScaler(minSize, maxSize);
	const scaledClusterData = data.map((n) => ({
		...n,
		sizeValue: getBubbleScale(n.sizeValue)
	}));

	// Rendering D3 elements
	const { root } = createD3BubbleRoot(scaledClusterData);
	const { node } = createD3Node(svg, root);
	const { tooltip } = createD3Tooltip();
	createD3NodeCircles(node);
	createD3NodeLabels(node);

	// Adding event handlers
	// Note: D3 loads in node data in "n"
	node.on('click', (_, n) => handleBubbleClick(n))
		.on('mouseenter', (e, n) => handleBubbleMouseEnter(e, n, tooltip, query))
		.on('mousemove', (e) => handleBubbleMouseMove(e, tooltip))
		.on('mouseout', (_, n) => handleBubbleMouseOut(n, tooltip));
};

/**
 * createD3BubbleRoot
 * @param data: formatted cluster data
 * @returns: { root }
 */
export function createD3BubbleRoot(data) {
	const bubble = d3
		.pack()
		.size([settings.diameter, settings.diameter])
		.padding(settings.bubbleMargin);

	const root = d3Hierarchy.hierarchy({ children: data }).sum((n) => n.sizeValue);

	bubble(root);

	return { root };
}

/**
 * createD3SVG
 * @param ref: SVG Ref
 * @returns: { svg }
 */
export function createD3SVG(ref) {
	const svg = d3
		.select(ref.current)
		.append('svg')
		.style('width', '100%')
		.style('height', '100%')
		.attr(
			'viewBox',
			`-${settings.svgPadding} -${settings.svgPadding} ${
				settings.diameter + settings.svgPadding * 2
			} ${settings.diameter + settings.svgPadding * 2}`
		);

	return { svg };
}

/**
 * createD3Node
 * @param svg D3 SVG
 * @param root
 * @returns: { node }
 * @note this is where animation classes are applied
 */
export function createD3Node(svg, root) {
	const node = svg
		.selectAll('g')
		.data(root.children)
		.enter()
		.append('g')
		.attr('class', 'node')
		.attr('transform', (n) => `translate(${n.x} ${n.y})`)
		.attr('inBasket', 'false')
		.append('g')
		.attr('data-id', (n) => n.data.key)
		.attr('class', 'animate-node-in')
		.attr('style', (_, index) => `animation-delay: ${index * 0.033}s`);

	return { node };
}

/**
 * createD3NodeCircles
 * @param node: D3 node
 * @returns node
 */
export function createD3NodeCircles(node) {
	node.append('circle')
		.attr('r', (n) => n.r)
		.attr('fill', (n) => {
			if (n.data.colorValue < 76 && n.data.colorValue >= settings.minimumBubbleSizeForText) {
				return `url(#${svgGradientIds.mid})`;
			} else if (
				n.data.colorValue < settings.minimumBubbleSizeForText &&
				n.data.colorValue >= 26
			) {
				return `url(#${svgGradientIds.low})`;
			} else if (n.data.colorValue < 26) {
				return '#FFF';
			} else {
				return `url(#${svgGradientIds.high})`;
			}
		});

	return node;
}

/**
 * Determines the size of a bubble based on the provided radius.
 *
 * @param {number} radius - The radius of the bubble.
 * @returns {Object} An object indicating the bubble size categories.
 */
function getBubbleSizeByRadius(radius) {
	// Define size categories based on the provided radius
	const xlargeBubble = radius > 80;
	const largeBubble = radius <= 80 && radius >= 65;
	const mediumBubble = radius <= 65 && radius >= 45;
	const smallBubble = radius < 45 && radius > 25;

	return {
		xlargeBubble,
		largeBubble,
		mediumBubble,
		smallBubble
	};
}

/**
 * Sets the keyword based on the provided radius, size value and index.
 *
 * @param {string} keyword - The original keyword.
 * @param {number} radius - The radius fo the bubble associated with the keyword.
 * @param {number} i - The index value for positioning.
 * @returns {string} The updated keyword based on radius and index.
 */
function setKeyword(keyword, radius, i) {
	const { xlargeBubble, largeBubble, mediumBubble, smallBubble } = getBubbleSizeByRadius(radius);
	let newKeyword = '';

	if (xlargeBubble) {
		newKeyword = keyword;
	} else if (largeBubble) {
		newKeyword = i < 4 ? keyword : '...';
	} else if (mediumBubble) {
		newKeyword = i < 3 ? keyword : i === 3 ? '...' : '';
	} else if (smallBubble) {
		newKeyword = i < 2 ? keyword : i === 2 ? '...' : '';
	} else {
		newKeyword = i === 0 ? '...' : '';
	}

	return newKeyword;
}

/**
 * Sets the font size based on the provided size value and radius.
 *
 * @param {number} radius - The radius fo the bubble associated with the keyword.
 * @returns {number} The font size percentage.
 */

function setFontSizePercent(radius) {
	const { xlargeBubble, largeBubble, mediumBubble, smallBubble } = getBubbleSizeByRadius(radius);

	let fontSize = 0;

	if (xlargeBubble || largeBubble) {
		fontSize = 0.18;
	} else if (mediumBubble) {
		fontSize = 0.25;
	} else if (smallBubble) {
		fontSize = 0.32;
	} else {
		fontSize = 1;
	}

	return fontSize;
}

/**
 * Sets the label padding percentage based on the provided size value and radius.
 *
 * @param {number} sizeValue - The size value of the bubble.
 * @param {number} radius - The radius fo the bubble associated with the keyword.
 * @returns {number} The label padding percentage.
 */
function setLabelPaddingPercent(radius) {
	const { xlargeBubble, largeBubble, mediumBubble, smallBubble } = getBubbleSizeByRadius(radius);

	let labelPaddingPercent = 0;

	if (xlargeBubble || largeBubble) {
		labelPaddingPercent = 0.23;
	} else if (mediumBubble) {
		labelPaddingPercent = 0.2;
	} else if (smallBubble) {
		labelPaddingPercent = 0.15;
	} else {
		labelPaddingPercent = 0;
	}

	return labelPaddingPercent;
}

/**
 * Sets the label line height
 *
 * @param {number} radius - The radius fo the bubble associated with the keyword.
 * @returns {number} The label line height
 */
function setLabelLineHeight(radius) {
	const { xlargeBubble, largeBubble, mediumBubble, smallBubble } = getBubbleSizeByRadius(radius);

	let labelPaddingPercent = 0;

	if (xlargeBubble || largeBubble) {
		labelPaddingPercent = 1.5;
	} else if (mediumBubble || smallBubble) {
		labelPaddingPercent = 1.3;
	} else {
		labelPaddingPercent = 1.5;
	}

	return labelPaddingPercent;
}

/**
 * createD3NodeLabels
 * @param node D3 node
 * @returns nothing, updates node
 */
export function createD3NodeLabels(node) {
	node.selectAll('text')
		.data((n) => {
			const t = n.data.keywords.map((keyword, index) => {
				return {
					keyword: setKeyword(keyword, n.r, index),
					index,
					fontSize: n.r * setFontSizePercent(n.r),
					radius: n.r,
					lineHeight: setLabelLineHeight(n.r),
					sizeValue: n.data.sizeValue,
					colorValue: n.data.colorValue,
					paddingPercent: setLabelPaddingPercent(n.r)
				};
			});

			return t;
		})
		.enter()
		.append('text')
		.text((t) => t.keyword) // append each keyword to it's own line
		.attr('text-anchor', 'middle')
		.attr('dy', (t) => {
			const { index, fontSize, lineHeight, paddingPercent } = t;

			const heightOfLine = fontSize * lineHeight;
			const diameter = t.radius * 2;
			const paddingAmount = diameter * paddingPercent;

			const lineStart = index * heightOfLine;

			// DY = 0 is the center of the bubble
			// Negative dy values move the text up
			// Positive dy values move the text down
			// Subtracting padding amount moves the text up to where the padding starts
			return lineStart - paddingAmount;
		})
		.attr('fill', (t) => {
			if (t.colorValue < 76 && t.colorValue >= settings.minimumBubbleSizeForText) {
				return theme.color.white;
			} else if (t.colorValue < settings.minimumBubbleSizeForText && t.colorValue >= 26) {
				return theme.color.grey900;
			} else if (t.colorValue < 26) {
				return theme.color.grey700;
			} else {
				return theme.color.white;
			}
		})
		.attr('font-size', (n) => `${n.fontSize}px`);

	return node;
}

/**
 * createD3Tooltip
 * @param containerClass: div container around SVG that tooltip will be appended to
 * @returns: { tooltip }
 * @note technically the tooltip is always rendered. Only thing that changes is the opacity to make it visible.
 */
export function createD3Tooltip() {
	const prevTooltip = document.querySelector('.tooltip');
	if (prevTooltip) prevTooltip.remove();

	const tooltip = d3
		.select(settings.containerClass)
		.append('div')
		.classed('tooltip', true)
		.style('position', 'fixed');

	return { tooltip };
}
