import { AnimatePresence } from 'framer-motion';
import { useParams, Navigate } from 'react-router-dom';

import { LibraryTransition } from '../LibraryTransition';
import { useClientQuery } from '../../../hooks/use-client-query';

import { LibraryLayout, LibraryLayoutHeader } from '../LibraryLayout';
import { FolderInfo } from '../Library.definitions';
import { LibraryPageError } from '../LibraryPageError';
import {
	LibraryFolderContent,
	LibraryFolderEmpty,
	LibraryFolderSkeleton,
	LibraryFolderNotFound
} from './LibraryFolderStates';

/**
 * Folder states:
 * 1. @isLoading
 * 2. @isEmpty Loads "This folder is empty" page - this will occur after delete file and add folder functionality is implemented
 * 3. @hasError (all errors except isEmpty): Loads full page error
 * 4. @hasData Loads page
 * 5. @isUnauthorized Redirects to signout/expired
 */
const LibraryFolderPage = () => {
	const { folderId } = useParams();
	const { data, statusObj } = useClientQuery<string, FolderInfo>('getFolderInfo', folderId);

	// Page states
	const { isSuccess, isError, isUnreachable, isLoading, isNotFound, isUnauthorized } = statusObj;
	const hasData = isSuccess && !!data && (!!data?.subFolders.length || !!data?.fileCount);
	const isEmpty = isSuccess && !data?.subFolders.length && !data?.fileCount;
	const hasError = (isError || isUnreachable) && !isNotFound;
	const hasHeader = isLoading || hasData;

	// Library header info
	const pageTitle = data?.name ?? 'Folder';
	const parentFolderName = data?.parent_folder?.name ? data.parent_folder.name : 'Library';
	const parentFolderLink = data?.parent_folder?.id
		? `/library/folder/${data.parent_folder.id}`
		: '/library';

	return (
		<LibraryLayout>
			{isUnauthorized && <Navigate to="/signout/expired" />}
			{hasHeader && (
				<LibraryLayoutHeader
					icon="Folder"
					title={pageTitle}
					backToLabel={parentFolderName}
					backTo={parentFolderLink}
					isLoading={isLoading}
				/>
			)}
			<AnimatePresence>
				{isLoading && (
					<LibraryTransition key="folderLoader" hasHeader={hasHeader}>
						<LibraryFolderSkeleton />
					</LibraryTransition>
				)}
				{isNotFound && (
					<LibraryTransition key="folderNotFound" delay={0.3} hasHeader={hasHeader}>
						<LibraryFolderNotFound />
					</LibraryTransition>
				)}
				{/* NOTE: isEmpty shouldn't occur yet, in future when we add create folder & delete file abilities this could occur */}
				{isEmpty && (
					<LibraryTransition key="folderEmpty" delay={0.3} hasHeader={hasHeader}>
						<LibraryFolderEmpty />
					</LibraryTransition>
				)}
				{hasError && (
					<LibraryTransition key="folderError" delay={0.3} hasHeader={hasHeader}>
						<LibraryPageError />
					</LibraryTransition>
				)}
				{hasData && (
					<LibraryTransition key="folderContent" delay={0.3} hasHeader={hasHeader}>
						<LibraryFolderContent data={data} folderId={folderId ?? ''} />
					</LibraryTransition>
				)}
			</AnimatePresence>
		</LibraryLayout>
	);
};

export default LibraryFolderPage;
