import { motion } from 'framer-motion';

type LibraryTransitionProps = {
	children: React.ReactNode;
	delay?: number;
	hasHeader?: boolean;
} & Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>;

/**
 * LibraryTransition
 * @use MUST BE USED AS DIRECT DESCENDANT OF <AnimatePresence> framer-motion component
 * @duration is set to 0.3s, add delay if needed to stagger exit/entrance animations
 */
export const LibraryTransition = ({
	children,
	className,
	delay = 0,
	hasHeader
}: LibraryTransitionProps) => {
	const minHeight = hasHeader ? 'calc(100% - 116px)' : '100%';

	return (
		<motion.div
			className={className}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.3, delay }}
			style={{ minHeight }}
		>
			{children}
		</motion.div>
	);
};
