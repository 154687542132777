import { css } from '@emotion/css';
import { theme } from 'cymantic-ui/dist/theme';

export const nodeLabelStyles = {
	wrapper: css`
		display: flex;
		align-items: center;
		gap: ${theme.space.space8};
		color: ${theme.color.grey700};

		&:hover {
			background: ${theme.color.grey200};
		}
	`,

	label: css`
		display: flex;
		align-items: center;
		gap: ${theme.space.space8};
		transition: color linear 0.2s;
		padding-right: ${theme.space.space2};

		.arrow {
			opacity: 0;
			transition: opacity linear 0.2s;
		}

		&:hover {
			color: ${theme.color.grey900};
			box-shadow: inset 0 -1px 0 0 ${theme.color.grey500};

			.arrow {
				opacity: 1;
			}
		}
	`,

	icon: css`
		display: inline-flex;
		align-items: center;
		justify-content: center;
	`,

	name: css`
		font-size: ${theme.fontSize.bodyXS};
	`,

	indicator: css`
		stroke: currentColor;
		fill: currentColor;
		stroke-width: 0;
		height: 1em;
		width: 1em;
		transform-origin: center center !important;
		vertical-align: middle;
	`,

	indicatorPath: css`
		fill: none;
		stroke: currentColor;
		stroke-width: 2px;
		stroke-linejoin: round;
		stroke-linecap: round;
	`
};

export const nodeGroupStyles = {
	group: css`
		list-style-type: none;
	`
};

export const nodeStyles = {
	node: css`
		position: relative;

		&:focus {
			outline: none;

			& > div:first-child {
				background: ${theme.color.grey200};
			}
		}
	`
};

export const rootStyles = {
	root: css`
		line-height: normal;
		list-style-type: none;

		* {
			line-height: normal !important;
		}
	`
};
