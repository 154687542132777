import { Action } from '../action';

const databasesActions = [
	'ADD_DATABASES',
	'SET_ACTIVE_DATABASE',
	'REMOVE_ACTIVE_DATABASE'
] as const;

export type DatabasesAction = (typeof databasesActions)[number];

type Databases = {
	activeDatabase?: string;
	allDatabases?: { [key: string]: string };
};

const initialState: Databases = {
	activeDatabase: undefined,
	allDatabases: undefined
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const databases = (state = initialState, action: Action<DatabasesAction>) => {
	switch (action.type) {
		case 'ADD_DATABASES': {
			const { data } = action.payload;

			const returnState: Databases = {
				...state,
				allDatabases: data
			};

			return returnState;
		}

		case 'SET_ACTIVE_DATABASE': {
			const { data } = action.payload;

			const returnState: Databases = {
				...state,
				activeDatabase: data
			};

			return returnState;
		}

		case 'REMOVE_ACTIVE_DATABASE': {
			const returnState: Databases = {
				...state,
				activeDatabase: undefined
			};

			return returnState;
		}

		default:
			return state;
	}
};

export default databases;
