import { css } from '@emotion/css';
import { theme } from 'cymantic-ui/dist/theme';

export const libraryLayout = css`
	display: flex;
	flex-direction: column;
	overflow: auto;
	height: 100%;
	max-height: 100vh;
	background: ${theme.color.grey50};
`;

export const libraryLayoutContent = css`
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: ${theme.space.space32};
	padding: ${theme.space.space32};
`;

export const libraryLayoutHeader = {
	root: css`
		position: sticky;
		top: 0;
		right: 0;
		display: flex;
		align-items: center;
		gap: ${theme.space.space16};
		padding: ${theme.space.space32};
		background: ${theme.color.white};
		border-bottom: 2px solid ${theme.color.grey200};
	`,
	left: css`
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: ${theme.space.space8};
		justify-content: center;
	`,
	right: css`
		display: flex;
		align-items: center;
		justify-content: center;
		width: min-content;
	`,
	title: css`
		display: flex;
		align-items: center;
		gap: ${theme.space.space8};
	`
};

export const libraryFolderRow = css`
	display: flex;
	flex-wrap: wrap;
	gap: ${theme.space.space16};
`;
