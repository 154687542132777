import React from 'react';
import { theme } from 'cymantic-ui/dist/theme';

export const svgGradientIds = {
	high: 'highRelevanceGradient',
	mid: 'midRelevanceGradient',
	low: 'lowRelevanceGradient'
};

const gradientRotation = 'rotate(-45)';

export const ClusterSVGSprites = () => (
	<svg
		version="1.1"
		id="icons"
		xmlns="http://www.w3.org/2000/svg"
		x="0px"
		y="0px"
		viewBox="0 0 200 200"
		style={{
			visibility: 'hidden',
			position: 'absolute',
			width: '0px',
			height: '0px',
			overflow: 'hidden'
		}}
	>
		<defs>
			<linearGradient
				id={svgGradientIds.high}
				gradientTransform={gradientRotation}
				x1="0%"
				x2="0%"
				y1="0%"
				y2="100%"
			>
				<stop
					offset="5%"
					style={{ stopColor: theme.color.primary700, stopOpacity: '1' }}
				></stop>
				<stop
					offset="95%"
					style={{ stopColor: theme.color.primary900, stopOpacity: '1' }}
				></stop>
			</linearGradient>
			<linearGradient
				id={svgGradientIds.mid}
				gradientTransform={gradientRotation}
				x1="0%"
				x2="0%"
				y1="0%"
				y2="100%"
			>
				<stop
					offset="5%"
					style={{ stopColor: theme.color.primary400, stopOpacity: '1' }}
				></stop>
				<stop
					offset="95%"
					style={{ stopColor: theme.color.primary600, stopOpacity: '1' }}
				></stop>
			</linearGradient>
			<linearGradient
				id={svgGradientIds.low}
				gradientTransform={gradientRotation}
				x1="0%"
				x2="0%"
				y1="0%"
				y2="100%"
			>
				<stop
					offset="5%"
					style={{ stopColor: theme.color.primary100, stopOpacity: '1' }}
				></stop>
				<stop
					offset="95%"
					style={{ stopColor: theme.color.primary200, stopOpacity: '1' }}
				></stop>
			</linearGradient>
		</defs>
	</svg>
);
