import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Text } from 'cymantic-ui/dist/atomic-components/text';

import { withContext } from '../../withContext';
import * as stateActions from '../../redux/stateActions';

import SearchForm from '../../components/SearchForm/SearchForm';
import { UserMenuWithAuth } from '../../components/Auth/UserMenuWithAuth';
import { isAuthOff } from '../../components/Auth/CognitoHelpers';
import TabViews from './TabViews';
import { grid, header, headerForm } from './ResultsPage.styles';

const ResultsPage = ({ searchRequest }) => {
	const navigate = useNavigate();

	useEffect(() => {
		if (searchRequest.index === '' || searchRequest.lens === '') {
			navigate('/');
		}
	}, [searchRequest, navigate]);

	return (
		<div className={grid}>
			<div className={header}>
				<div className={headerForm}>
					<Link to="/">
						<Text tag="span" variant="headerH4">
							RUTHI
						</Text>
					</Link>
					<SearchForm hideFormErrors />
				</div>
				{!isAuthOff() ? <UserMenuWithAuth /> : null}
			</div>
			<TabViews />
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		searchRequest: state.pub,
		signedIn: state.user.signedIn
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		changeSigningIn: (signingIn) => dispatch(stateActions.setSigningIn(signingIn))
	};
};
const ResultsPageContainer = withContext(connect(mapStateToProps, mapDispatchToProps)(ResultsPage));
export default ResultsPageContainer;
