import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import Context, { withContext } from '../../withContext';
import * as stateActions from '../../redux/stateActions';
import {
	Button,
	Input,
	Select,
	ScreenReaderOnly,
	InlineAlert
} from 'cymantic-ui/dist/atomic-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { searchForm, searchSection } from './SearchForm.styles';
import { isAuthOff } from '../Auth/CognitoHelpers';

const SearchForm = ({
	showResults,
	toggleResults,
	searchResult,
	hideFormErrors,
	activeDatabase,
	allDatabases,
	addDatabases,
	setActiveDatabase
}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const client = useContext(Context);

	/**
	 * @Note searchResult.index === database mostly
	 * The exception is when the user selects a file from their library - in that case, the index is 'shannon'
	 * The searchResult (and by extension searchResult.index) redux state maintains the information needed to run the search query.
	 * The databases redux state (allDatabases, activeDatabase) maintains the information needed for the database select dropdown.
	 */
	const { q, index: searchResultIndex, lens } = searchResult;

	const [lensMap, setLensMap] = useState({});

	const defaultDatabaseOption = { label: 'Select a category', disabled: true, value: '' };

	const {
		register,
		handleSubmit,
		watch,
		reset,
		formState: { errors }
	} = useForm();

	React.useEffect(() => {
		if (location.pathname === '/') {
			reset({ query: '', database: '', lens: '' });
		}
		if (location.pathname === '/results') {
			reset({ query: q, database: activeDatabase, lens });
		}
	}, [location, reset]);

	const databaseValue = watch('database');

	// Checks if databases have been defined in redux store
	const areDatabasesInitialized = !(typeof allDatabases === 'undefined');
	const databaseOptions = areDatabasesInitialized
		? [
				defaultDatabaseOption,
				...Object.entries(allDatabases).map(([database, databaseLabel]) => ({
					value: database,
					label: databaseLabel
				}))
		  ]
		: [defaultDatabaseOption];

	// Adds databases to redux store, ensures that this is only called once
	React.useEffect(() => {
		if (areDatabasesInitialized) {
			return;
		}

		const getDatabaseMap = async () => {
			const { data: databasesData, statusObj } = await client.getDatabaseMap();

			const { isUnauthorized, isError, isUnreachable } = statusObj;
			const isAuthenticationOff = isAuthOff();

			if (isError || isUnreachable) {
				addDatabases({});
			}

			if (!isAuthenticationOff) {
				if (isUnauthorized) {
					navigate('/signout/expired');
				}
				const { data: userFiles } = await client.getFiles({ folderId: '', page: '' });
				const userFilesData = userFiles
					.map((file) => {
						return { [`file-${file.id}`]: file.name };
					})
					.reduce((accumulator, currentObject) => {
						return { ...accumulator, ...currentObject };
					}, {});

				addDatabases({ ...databasesData, ...userFilesData });
			} else {
				addDatabases(databasesData);
			}
		};

		getDatabaseMap();
	}, [areDatabasesInitialized]);

	// Gets lens map for selected database
	React.useEffect(() => {
		const getLensMap = async (index) => {
			const { data, isUnauthorized } = await client.getLensMap(index);
			setLensMap(data);

			if (isUnauthorized) {
				navigate('/signout/expired');
			}
		};
		getLensMap(databaseValue);
		setActiveDatabase(databaseValue);
	}, [databaseValue]);

	const onSubmit = (data) => {
		const searchInfo = {
			query: data.query,
			index: data.database,
			lens: data.lens
		};

		// TODO: this is gross, we need to find another way to distinguish shannon user files.
		if (data.database.startsWith('file-')) {
			searchInfo.file = data.database.replace('file-', '');
			searchInfo.index = 'shannon';
		}

		toggleResults(client, searchInfo, showResults);

		if (location.pathname !== '/results') {
			navigate('/results');
		}
	};

	const lensOptions =
		(databaseValue || searchResultIndex) && lensMap[databaseValue || searchResultIndex]
			? [
					{ label: 'Select a lens', disabled: true, value: '' },
					...Object.entries(lensMap[databaseValue || searchResultIndex].lenses).map(
						([lens, lensLabel]) => ({
							value: lens,
							label: lensLabel
						})
					)
			  ]
			: [];

	const hasErrors = Object.keys(errors).length > 0;
	const errorMessage =
		errors.query?.message || errors.index?.message || errors.lens?.message || '';

	return (
		<section className={searchSection}>
			<ScreenReaderOnly>
				<h2>Search</h2>
				<div>
					All fields are required. Enter a search term, select a category, and select a
					lens.
				</div>
			</ScreenReaderOnly>

			<form
				className={searchForm}
				aria-label="Search"
				aria-describedby={hasErrors ? '#searchErrors' : undefined}
				onSubmit={handleSubmit(onSubmit)}
			>
				<Input
					{...register('query')}
					defaultValue={q || ''}
					iconLeft="Search"
					hideLabel
					label="Enter a search term"
					placeholder="Search for anything"
					type="text"
					variant="sm"
				/>

				<Select
					{...register('database', { required: 'Please select a category' })}
					isInvalid={!!errors.database?.type}
					defaultValue={searchResultIndex || ''}
					hideLabel
					label="Select a category"
					options={databaseOptions}
					variant="sm"
				/>

				{(databaseValue || searchResultIndex) &&
					lensOptions &&
					lensMap[databaseValue || searchResultIndex] && (
						<Select
							{...register('lens', { required: 'Please select a lens' })}
							isInvalid={!!errors.lens?.type}
							defaultValue={
								lens || lensMap[databaseValue || searchResultIndex].default || ''
							}
							hideLabel
							label="Select a lens"
							options={lensOptions}
							variant="sm"
						/>
					)}

				<Button label="Search" variant="primary" size="sm" />
			</form>

			{hideFormErrors ? (
				<ScreenReaderOnly>
					{hasErrors && (
						<InlineAlert variant="error" size="xs" id="search-errors">
							{errorMessage}
						</InlineAlert>
					)}
				</ScreenReaderOnly>
			) : (
				<div
					style={{ visibility: hasErrors ? 'visible' : 'hidden' }}
					aria-hidden={hasErrors || undefined}
				>
					<InlineAlert variant="error" size="xs" id="search-errors">
						{errorMessage}
					</InlineAlert>
				</div>
			)}
		</section>
	);
};

const mapStateToProps = (state) => ({
	showResults: state.container.showResults,
	searchResult: state.pub,
	allDatabases: state.databases.allDatabases,
	activeDatabase: state.databases.activeDatabase
});

//This is for state management. We dont use this function to call the search() function in client.js. It's redundant code but I left it because it's a good example of what you might do.
const mapDispatchToProps = (dispatch) => {
	return {
		addDatabases: (databases) => dispatch(stateActions.addDatabases(databases)),
		setActiveDatabase: (database) => dispatch(stateActions.setActiveDatabase(database)),
		toggleResults: (client, searchInfo, showResults) => {
			dispatch(stateActions.setLoading());
			dispatch(stateActions.clearDepth());
			dispatch(stateActions.searchRequest(searchInfo));
			client.query_cluster(searchInfo);
			dispatch(stateActions.toggleSearchPage(showResults));
		}
	};
};

const SearchFormContainer = withContext(connect(mapStateToProps, mapDispatchToProps)(SearchForm));

export default SearchFormContainer;
