import { SkeletonAreaLoader, CenterContent, Link, Text } from 'cymantic-ui/dist/atomic-components';

import {
	CenteredLayoutContainer,
	CenteredLayoutSubContainer
} from '../../../components/CenteredLayout/centered-layout';
import { FolderLink } from '../../../components/FolderLink/FolderLink';
import { PageSection } from '../../../components/PageSection/PageSection';

import { LibraryLayoutContent, LibraryFolderRow } from '../LibraryLayout';
import { LibraryTable } from '../LibraryTable/LibraryTable';
import { LibraryTableNoFiles, LibraryTableSkeleton } from '../LibraryTable/LibraryTableStates';
import { FolderInfo } from '../Library.definitions';
import { getShannonLink } from '../../../utilities/get-shannon-link';

export const LibraryPageEmpty = () => {
	// TODO: Double check this link
	const shannonLink = getShannonLink();

	return (
		<CenterContent isFullHeight>
			<CenteredLayoutContainer>
				<CenteredLayoutSubContainer>
					<Text tag="h1" variant="headerH4" textAlign="center">
						You don’t have any Shannon meeting transcripts yet.
					</Text>
					<Text tag="p" variant="bodySM" color="grey700" textAlign="center">
						When meeting transcripts are saved they will appear here.
					</Text>
				</CenteredLayoutSubContainer>
				<CenteredLayoutSubContainer>
					<Link
						href={shannonLink}
						isBlank
						iconRight="ExternalLink"
						label="Learn more about Shannon meetings"
					/>
				</CenteredLayoutSubContainer>
			</CenteredLayoutContainer>
		</CenterContent>
	);
};

export const LibraryPageSkeleton = () => (
	<LibraryLayoutContent>
		<PageSection title=" " isLoading>
			<LibraryFolderRow>
				<SkeletonAreaLoader height="46px" width="120px" />
				<SkeletonAreaLoader height="46px" width="120px" />
				<SkeletonAreaLoader height="46px" width="120px" />
			</LibraryFolderRow>
		</PageSection>
		<PageSection title=" " description=" " isLoading>
			<LibraryTableSkeleton />
		</PageSection>
	</LibraryLayoutContent>
);

type LibraryPageContentProps = {
	data: FolderInfo;
};

export const LibraryPageContent = ({ data }: LibraryPageContentProps) => {
	const hasSubFolders = !!data && data.subFolders.length > 0;
	const hasFiles = !!data && data?.fileCount >= 1;

	return (
		<LibraryLayoutContent>
			{hasSubFolders && (
				<PageSection title="Folders">
					<LibraryFolderRow>
						{data.subFolders.map((subfolder) => {
							return (
								<FolderLink
									key={subfolder.id}
									label={subfolder.name}
									to={`/library/folder/${subfolder.id}`}
								/>
							);
						})}
					</LibraryFolderRow>
				</PageSection>
			)}
			<PageSection title="All Files" description="Recent files from all folders">
				{hasFiles ? (
					<LibraryTable folderId="" fileCount={data.fileCount} />
				) : (
					<LibraryTableNoFiles />
				)}
			</PageSection>
		</LibraryLayoutContent>
	);
};
