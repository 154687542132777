import * as React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { getStatus } from '../../utilities/get-status';
// Components
import DocPanel from './DocPanel/DocPanel';
import ClusterView from './ClusterView/ClusterView';

// components
import RelativityPanel from './RelativityPanel';
//import FilterPanel from './FilterPanel/FilterPanel';
//import { Button } from 'cymantic-ui';
import { IconButton } from 'cymantic-ui';
import { isLocalHost } from '../../utilities/is-local-host';

const TabViews = ({
	searchResults,
	searchInfo,
	selectedDocCount,
	currentDepth,
	notAllSelected,
	hasClusterVisData
}) => {
	const reclusterRef = React.useRef(null);

	const [isDocPanelExpanded, setIsDocPanelExpanded] = React.useState(false);
	//const [isFilterPanelVisible, setIsFilterPanelVisible] = React.useState(false);

	const handleToggleDocPanel = () => setIsDocPanelExpanded(!isDocPanelExpanded);
	//const handleToggleFilterPanel = () => setIsFilterPanelVisible(!isFilterPanelVisible);

	const { q: query } = searchInfo;
	const { code, cluster_dom, document_dom } = searchResults.search;

	const { isLoading, isSuccess } = getStatus(code);
	const hasDocumentsNoClusters =
		!cluster_dom && document_dom && Object.keys(document_dom).length > 0 && !isLoading;

	const prodTabs = [
		{
			index: '1',
			label: 'Cluster View',
			content: <ClusterView ref={reclusterRef} />
		}
	];

	const tabs = [
		{
			index: '1',
			label: 'Cluster View',
			content: <ClusterView ref={reclusterRef} />
		},
		{
			index: '2',
			label: 'Table View',
			content: 'TBD'
		}
	];

	// TODO remove after implementing tableview
	const tabArray = isLocalHost() ? tabs : prodTabs;
	const [activeTab, setActiveTab] = React.useState(tabs[0].index);

	React.useEffect(() => {
		// TODO: if user is searching the same query over and over, it won't close the sidebar
		// Perhaps make state method for the doc panel
		setIsDocPanelExpanded(false);
	}, [query]);

	React.useEffect(() => {
		if (hasDocumentsNoClusters && !isLoading) {
			setIsDocPanelExpanded(true);
		}
	}, [hasDocumentsNoClusters, isLoading]);

	return (
		<>
			<ul data-component="TabList">
				{tabArray.map((tab) => (
					<li
						key={tab.index}
						className={classnames(
							'tab-list__item',
							activeTab === tab.index ? 'tab_list__item--active' : ''
						)}
					>
						<button onClick={() => setActiveTab(tab.index)}>{tab.label}</button>
					</li>
				))}
			</ul>
			<div data-component="TabContent">
				<div className="tab-content__content">
					{/* Temporarily Hiding Filter */}
					{/* isLocalHost() && (
						<>
							<div className="tab-content__top-controls">
								<Button
									iconLeft="Filter"
									isActive={isFilterPanelVisible}
									label="Show Filter"
									size="xs"
									variant="outline"
									onClick={handleToggleFilterPanel}
									disabled={isLoading || !hasClusterVisData}
								/>
							</div>

							{ {<FilterPanel isVisible={isFilterPanelVisible} /> &&
										searchInfo.index != 'shannon'} }
							<FilterPanel isVisible={isFilterPanelVisible} />
						</>
					)*/}

					<div className="tab-content__relativity-panel">
						<RelativityPanel />
					</div>

					{tabArray.map((tab) => (
						<div
							key={`${tab.label}${tab.index}`}
							// hide the non-active tab content
							className="tab-content__content-inner"
							style={
								activeTab === tab.index ? { height: '100%' } : { display: 'none' }
							}
						>
							{tab.content}
						</div>
					))}

					<div className="tab-content__bottom-controls">
						<IconButton
							icon="List"
							variant="outline"
							onClick={handleToggleDocPanel}
							ariaLabel="Toggle Document Panel"
							size="md"
							disabled={!isSuccess}
						/>
						<IconButton
							icon="ZoomIn"
							onClick={() => reclusterRef.current.recluster('zoomIn')}
							variant="outline"
							ariaLabel="Zoom In"
							size="md"
							disabled={
								isLoading ||
								(selectedDocCount <= 22 && notAllSelected) ||
								!hasClusterVisData
							}
						/>
						<IconButton
							icon="ZoomOut"
							variant="outline"
							onClick={() => reclusterRef.current.recluster('zoomOut')}
							ariaLabel="Zoom Out"
							size="md"
							disabled={isLoading || currentDepth <= 1 || !hasClusterVisData}
						/>
					</div>
				</div>
				<DocPanel isExpanded={isDocPanelExpanded} handleClose={handleToggleDocPanel} />
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	const basket = state.cluster_vis.basket;
	const dataLen = Object.entries(state.cluster_vis.data).length;

	return {
		searchResults: state.sub,
		searchInfo: state.pub,
		hasClusterVisData: state.cluster_vis.data.length,
		selectedDocCount: state.cluster_vis.selectedMemberDocs.length,
		currentDepth: state.cluster_vis.depth,
		notAllSelected: basket.length !== dataLen
	};
};

export default connect(mapStateToProps, null, null)(TabViews);
