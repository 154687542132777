import * as React from 'react';
import { handleRedirectToSignIn, handleRedirectToSignUp } from './CognitoHelpers';
import Cookies from 'js-cookie';
import { Button, LinkButton, Text } from 'cymantic-ui';
import { container } from './HomePageAuthRedirect.styles';

export const HomePageAuthRedirect = () => {
	const hasSignedIn = Cookies.get('hasSignedIn') === 'true';

	return hasSignedIn ? (
		<div className={container}>
			<Button size="md" label="Sign In" onClick={handleRedirectToSignIn} />
			<Text color="grey600" variant="bodyXS" textAlign="center">
				Don&apos;t have an account?{' '}
				<LinkButton label="Sign Up" onClick={handleRedirectToSignUp} />
			</Text>
		</div>
	) : (
		<div className={container}>
			<Button size="md" label="Sign Up" onClick={handleRedirectToSignUp} />
			<Text color="grey600" variant="bodyXS" textAlign="center">
				Already have an account?{' '}
				<LinkButton label="Sign In" onClick={handleRedirectToSignIn} />
			</Text>
		</div>
	);
};
