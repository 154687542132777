let initialState = {
	q: '',
	index: '',
	lens: ''
};

const pub = (state = initialState, action) => {
	switch (action.type) {
		case 'SEARCH_REQUEST': {
			const { query, index, lens, file } = action.payload.data;
			if (index) {
				const newState = Object.assign(
					{},
					{ ...state },
					{ q: query, index: index, lens: lens, file: file }
				);
				return newState;
			} else return state;
		}
		default:
			return state;
	}
};

export default pub;
