import * as React from 'react';
import { fromUnixTime, format } from 'date-fns';

import { SkeletonTextLoader, Text, RouterLink } from 'cymantic-ui/dist/atomic-components';

import { getTimezoneAbbreviation, getDurationFromUnix } from '../../../utilities/time-formatting';
import { StatusObj } from '../../../utilities/get-status';
import { PageError } from '../../../components/PageError/PageError';
import { PageNotFound } from '../../../components/PageNotFound/PageNotFound';

import { Transcript } from './TranscriptFilePage.definitions';
import { transcriptFilePage } from './TranscriptFilePage.styles';
import { TranscriptRow, TranscriptRowSkeleton } from './TranscriptRow';

export const TranscriptFileError = () => (
	<PageError
		title="An error occurred while retrieving your file"
		subtitle="Please try again later."
		alertVariant="error"
	/>
);

export const TranscriptNotFound = () => (
	<PageNotFound
		title="File not found"
		subtitle="The file you are looking for doesn’t exist or has been moved."
	>
		<RouterLink to="/library" label="Return to Library" iconLeft="ArrowLeft" />
	</PageNotFound>
);

export const TranscriptFileSkeleton = () => (
	<>
		<div className={transcriptFilePage.info}>
			<SkeletonTextLoader variant="bodyXS" width="200px" />
			<SkeletonTextLoader variant="bodyXS" width="300px" />
		</div>
		<div className={transcriptFilePage.transcript}>
			{[...Array(10).keys()].map((i) => (
				<TranscriptRowSkeleton key={i} />
			))}
		</div>
	</>
);

type TranscriptFileContentProps = {
	data: Transcript;
	statusObj: StatusObj;
};

export const TranscriptFileContent = ({ data, statusObj }: TranscriptFileContentProps) => {
	const { isSuccess } = statusObj;
	const participants = data?.content.participants.join(', ');

	const hasTranscripts = data && data.content.transcript.length > 0;

	const [meetingDurationHours, setMeetingDurationHours] = React.useState(0);
	const [startTime, setStartTime] = React.useState('');

	const firstTimestamp = data?.content.transcript[0].timestamp;
	const lastTimestamp = data?.content.transcript[data.content.transcript.length - 1].timestamp;

	React.useEffect(() => {
		if (isSuccess && firstTimestamp && lastTimestamp) {
			const { hours } = getDurationFromUnix(firstTimestamp, lastTimestamp);

			if (hours && hours > 0) {
				setMeetingDurationHours(hours);
			}
		}
	}, [isSuccess, hasTranscripts, firstTimestamp, lastTimestamp]);

	React.useEffect(() => {
		if (isSuccess && firstTimestamp) {
			const startTimeFromUnix = fromUnixTime(firstTimestamp / 1000);
			const startDateString = format(startTimeFromUnix, 'EEEE LLL d, yyyy');
			const startTimeString = format(startTimeFromUnix, 'h:mm a');
			const timeZoneAbbreviation = getTimezoneAbbreviation();
			setStartTime(`${startDateString} | ${startTimeString} ${timeZoneAbbreviation}`);
		}
	}, [isSuccess, firstTimestamp]);

	return (
		<>
			<div className={transcriptFilePage.info}>
				{startTime && (
					<Text color="grey600" variant="bodyXS">
						{startTime}
					</Text>
				)}
				<Text color="grey900" variant="bodyXS">
					{participants}
				</Text>
			</div>
			<ul className={transcriptFilePage.transcript}>
				{firstTimestamp &&
					data.content.transcript.map((item) => {
						const { text, speakerName, timestamp } = item;
						return (
							<TranscriptRow
								key={`${item.speakerName}${item.timestamp}`}
								firstTimestamp={firstTimestamp}
								hasHours={meetingDurationHours > 0}
								speakerName={speakerName}
								text={text}
								timestamp={timestamp}
							/>
						);
					})}
			</ul>
		</>
	);
};
