import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { ThemeInitializer, ruthiTheme } from 'cymantic-ui';

import Client from './client';

// eslint-disable-next-line import/no-cycle
// New linting (airbnb config) found circular dependency in App...but I can't figure out what it is..
// TODO: Find circular dependency
import App from './components/App';
import store from './store';
import Context from './withContext';

import './css/index.css';

// styling file
const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

Client.init({ store });
const client = new Client();

root.render(
	<Provider store={store}>
		<Context.Provider value={client}>
			<ThemeInitializer theme={ruthiTheme}>
				<App />
			</ThemeInitializer>
		</Context.Provider>
	</Provider>
);
