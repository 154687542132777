import { combineReducers } from 'redux';
import cluster_vis from './cluster_vis.js';

// TODO: Use react router instead of booleans to determine "page";
import container from './container.js';
import pub from './pub.js';
import sub from './sub.js';
import user from './user.js';
import databases from './databases.ts';

const reducers = combineReducers({ pub, sub, container, user, cluster_vis, databases });

export default reducers;
