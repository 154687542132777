import * as React from 'react';
import { AnimatePresence } from 'framer-motion';
import { Navigate } from 'react-router-dom';
import { TruncatedPagination } from 'cymantic-ui/dist/atomic-components/pagination';

import { LibraryTransition } from '../LibraryTransition';
import { StatusObj } from '../../../utilities/get-status';
import Context from '../../../withContext';

import { paginationWrapper } from './LibraryTable.styles';
import { LibraryFile } from './LibraryTable.definitions';
import {
	LibraryTableError,
	LibraryTableNoFiles,
	LibraryTableSkeleton,
	LibraryFilesTable
} from './LibraryTableStates';

type LibraryTableProps = {
	folderId: string;
	fileCount: number;
};

/**
 * Table states:
 * 1. @isLoading
 * 2. @hasError Loads error block
 * 3. @hasNoFiles Loads "Folder is empty" block
 * 4. @hasFiles Loads table
 * 5. @isUnauthorized Redirects to signout/expired
 */
export const LibraryTable = ({ folderId, fileCount = 0 }: LibraryTableProps) => {
	const client = React.useContext(Context);

	const [tableData, setTableData] = React.useState<LibraryFile[]>([]);
	const [status, setStatus] = React.useState<StatusObj>({
		isLoading: true,
		isSuccess: false,
		isError: false,
		isUnreachable: false,
		isErrorNoResults: false,
		isUnauthorized: false,
		isNotFound: false
	});

	const [page, setPage] = React.useState(1);
	const [isPageLoading, setIsPageLoading] = React.useState(false);
	const pageSize = 15;
	const minimumPageTransitionTime = 1000;
	const pageCount = Math.ceil(fileCount / pageSize);
	const isPaginated = pageCount > 1;

	const { isErrorNoResults, isError, isUnreachable, isSuccess, isLoading, isUnauthorized } =
		status;
	const isTableLoading = isLoading || isPageLoading;
	const hasError = !isTableLoading && (isErrorNoResults || isError || isUnreachable);
	const hasFiles = !isTableLoading && isSuccess && tableData.length > 0;
	const hasNoFiles = !isTableLoading && isSuccess && tableData.length === 0;

	React.useEffect(() => {
		const getQuery = async () => {
			const response = await client.getFiles({ folderId, page });

			if (response?.statusObj) {
				setStatus(response.statusObj);

				if (response?.data && response?.statusObj?.isSuccess) {
					setTableData(response.data);
				}
			}
			const timeout = setTimeout(() => setIsPageLoading(false), minimumPageTransitionTime);
			return () => clearTimeout(timeout);
		};

		getQuery();
	}, [folderId, page, client]);

	const handleSetPage = (pageNum: number) => {
		setPage(pageNum);
		setIsPageLoading(true);
	};

	return (
		<>
			{isUnauthorized && <Navigate to="/signout/expired" />}
			<AnimatePresence>
				{isTableLoading && (
					<LibraryTransition key="tableLoader">
						<LibraryTableSkeleton />
					</LibraryTransition>
				)}
				{hasFiles && (
					<LibraryTransition key="tableFiles" delay={0.3}>
						<LibraryFilesTable data={tableData} />
						{isPaginated && (
							<div className={paginationWrapper}>
								<TruncatedPagination
									setPage={handleSetPage}
									page={page}
									pageCount={pageCount}
								/>
							</div>
						)}
					</LibraryTransition>
				)}
				{/* hasNoFiles: should be caught in LibraryPage or LibraryFolderPage component, but if not it will be caught here */}
				{hasNoFiles && (
					<LibraryTransition key="tableNoFiles" delay={0.3}>
						<LibraryTableNoFiles />
					</LibraryTransition>
				)}
				{hasError && (
					<LibraryTransition key="tableError" delay={0.3}>
						<LibraryTableError />
					</LibraryTransition>
				)}
			</AnimatePresence>
		</>
	);
};
