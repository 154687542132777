import { isLocalHost } from '../../utilities/is-local-host';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import Client from '../../client';
import { logError } from '../../utilities/logger';

const AUTH_FLAG = process.env.REACT_APP_AUTHENTICATION_FLAG;
const COGNITO_HOST = process.env.REACT_APP_COGNITO_HOST;
const COGNITO_REDIRECT = isLocalHost()
	? 'http://localhost:3000'
	: process.env.REACT_APP_COGNITO_REDIRECT;
const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID;

const signUpUrl = `${COGNITO_HOST}/signup?client_id=${COGNITO_CLIENT_ID}&response_type=code&scope=email+openid+phone&redirect_uri=${encodeURIComponent(
	COGNITO_REDIRECT
)}`;
const loginUrl = `${COGNITO_HOST}/login?client_id=${COGNITO_CLIENT_ID}&response_type=code&scope=email+openid+phone&redirect_uri=${encodeURIComponent(
	COGNITO_REDIRECT
)}`;
const logoutUrl = `${COGNITO_HOST}/logout?client_id=${COGNITO_CLIENT_ID}&logout_uri=${encodeURIComponent(
	COGNITO_REDIRECT
)}`;
const tokenUrl = `${COGNITO_HOST}/oauth2/token`;

export const getDomain = () => (isLocalHost() ? {} : { domain: 'cymantix.com' });

export function retrieveAuthCodeFromUrl() {
	const url = window.location.href;

	const params = new URLSearchParams(new URL(url).search);
	const code = params.get('code');

	return code;
}

export async function checkIfTokenIsValid() {
	const domain = getDomain();
	const idToken = Cookies.get('idToken', domain);

	const client = new Client();
	try {
		return await client.checkCognitoToken(idToken);
	} catch (error) {
		const errorMessage = 'Error while checking Cognito token: ' + error;
		logError(errorMessage);
		throw new Error(errorMessage);
	}
}

export function checkTokenExists() {
	const domain = getDomain();
	const idToken = Cookies.get('idToken', domain);
	return !!idToken;
}

export function getUserDataFromToken() {
	const domain = getDomain();
	const idToken = Cookies.get('idToken', domain);

	if (!idToken) {
		return { name: '', email: '' };
	}

	const { first_name, last_name, email } = jwtDecode(idToken);
	const name =
		first_name && last_name
			? `${first_name} ${last_name}`
			: first_name
			? first_name
			: last_name
			? last_name
			: '';
	return { name, email };
}

export async function handleSignInFlow(setUserData) {
	const authCode = retrieveAuthCodeFromUrl();
	const domain = getDomain();

	let idToken = '';
	let accessToken = '';

	try {
		({ idToken, accessToken } = await requestCognitoToken(authCode));
	} catch (error) {
		const errorMessage = 'Error while requesting Cognito token: ' + error;
		logError(errorMessage);
		Cookies.remove('idToken', domain);
		Cookies.remove('accessToken', domain);
		setUserData({ name: '', email: '' });
		throw new Error(errorMessage);
	}

	Cookies.set('idToken', idToken, domain);
	Cookies.set('accessToken', accessToken, domain);

	const userData = getUserDataFromToken();
	setUserData(userData);
}

export function handleSignOutFlow(setUserData) {
	// tokens were already removed before redirecting user to Cognito for sign out,
	// however removing here again to be safe.
	const domain = getDomain();
	Cookies.remove('idToken', domain);
	Cookies.remove('accessToken', domain);
	setUserData({ name: '', email: '' });
	Cookies.set('CognitoSigningOutRedirection', 'false');
}

export const handleRedirectToSignUp = () => window.location.assign(signUpUrl);

export const handleRedirectToSignIn = () => {
	try {
		window.location.assign(loginUrl);
		Cookies.set('CognitoSigningOutRedirection', 'false');
	} catch (error) {
		throw new Error('Error while signing in user to Cognito : ', error);
	}
};

export const handleRedirectToSignOut = () => {
	try {
		window.location.assign(logoutUrl);
		Cookies.set('CognitoSigningOutRedirection', 'true');

		// Tokens should be removed at this point so when user is redirected back to our page, sign in button can be rendered.
		const domain = getDomain();
		Cookies.remove('idToken', domain);
		Cookies.remove('accessToken', domain);
	} catch (error) {
		throw new Error('Error while signing out user from Cognito : ', error);
	}
};

/**
 * @param  {string} code // Authorization code from Cognito
 */
export const requestCognitoToken = async (code) => {
	const requestBody = new URLSearchParams();
	requestBody.append('grant_type', 'authorization_code');
	requestBody.append('code', code);
	requestBody.append('client_id', COGNITO_CLIENT_ID);
	requestBody.append('redirect_uri', COGNITO_REDIRECT);

	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		body: requestBody.toString()
	};

	try {
		const response = await fetch(tokenUrl, requestOptions);
		const data = await response.json();
		const { id_token, access_token } = data;
		return { idToken: id_token, accessToken: access_token };
	} catch (error) {
		const errorMessage = 'Error while requesting Cognito token: ' + error;
		logError(errorMessage);
		throw new Error(errorMessage);
	}
};

export const isAuthOff = () => {
	const isAuthOff = !AUTH_FLAG || AUTH_FLAG.toLowerCase() === 'false';
	return isAuthOff;
};
