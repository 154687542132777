import { theme } from 'cymantic-ui/dist/theme';

import * as d3 from 'd3';
import { addNodeToBasket, removeNodeFromBasket } from '../../../redux/stateActions.js';
import store from '../../../store';

function tooltipToggle(isVisible, tooltip) {
	if (isVisible) {
		tooltip.classed('tooltip--visible', true);
	} else {
		tooltip.classed('tooltip--visible', false);
	}
}

/**
 * handleBubbleMouseEnter: Updates visibility and content of tooltip
 * @param {*} node: D3 node
 * @param {*} tooltip: tooltip instance
 * @param {*} query: what user searched
 */
export function handleBubbleMouseEnter(event, node, tooltip, query) {
	const keywords = node.data.keywords;
	tooltipToggle(true, tooltip);

	const relevanceContent =
		query.trim() !== ''
			? `
			<div class='tooltip__relevance-text'>
				<span class="tooltip__bold-text">
					${node.data.score.toFixed(2)}%
				</span> relevance to <span class="tooltip__bold-text">${query}</span>
			</div>`
			: '';

	tooltip.html(
		`
			<div class="tooltip__content">
				<div class="tooltip__label-tags">
					${keywords.map((keyword) => `<span class="tooltip__label-tag">${keyword}</span>`).join(' ')}
				</div>
				${relevanceContent}
			</div>
		`
	);
	tooltip.style('top', event.offsetY + 140 + 'px').style('left', event.offsetX + 70 + 'px');
}

/**
 * handleBubbleMouseMove: Updates position of tooltip, insures tooltip is visible
 * @param event
 * @param tooltip: tooltip instance
 */
export function handleBubbleMouseMove(event, tooltip) {
	tooltipToggle(true, tooltip);
	tooltip.style('top', event.offsetY + 140 + 'px').style('left', event.offsetX + 70 + 'px');
}

/**
 * handleBubbleMouseOut: Updates opacity of tooltip
 * @param tooltip: tooltip instance
 */
export function handleBubbleMouseOut(node, tooltip) {
	tooltipToggle(false, tooltip);
}

/**
 * handleBubbleClick
 * @param {*} node: D3 node
 * @param {*} addNodeToBasket
 * @param {*} removeNodeFromBasket
 */
export function handleBubbleClick(node) {
	const { key } = node.data;
	const clicked = d3.select(`[data-id='${key}']`);
	const inBasket = clicked.attr('inBasket') === 'true';

	if (!inBasket) {
		clicked
			.attr('inBasket', true)
			.select('circle')
			.attr('r', (n) => n.r)
			.attr('stroke', (n) => {
				if (n.data.colorValue < 76) {
					return theme.color.primary900;
				} else {
					return '#373AD7'; // TODO: Update to theme color: blocked by https://cymantix.atlassian.net/browse/CYUI-39
				}
			})
			.style('stroke-width', 4);
		store.dispatch(addNodeToBasket(key));
	} else {
		clicked.attr('inBasket', false).select('circle').style('stroke-width', 0);
		store.dispatch(removeNodeFromBasket(key));
	}
}
