import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { RouterLink, SkeletonAreaLoader } from 'cymantic-ui/dist/atomic-components';
import { format } from 'date-fns';
import { PageSectionError } from '../../../components/PageSectionError/PageSectionError';

import { libraryTable } from './LibraryTable.styles';
import { columns, LibraryFile } from './LibraryTable.definitions';

export const LibraryTableError = () => (
	<PageSectionError
		title="An error occurred while retrieving your files"
		subtitle="Please try again later."
		alertVariant="error"
	/>
);

export const LibraryTableNoFiles = () => (
	<PageSectionError title="This folder is currently empty." />
);

export const LibraryTableSkeleton = () => (
	<SkeletonAreaLoader variant="rounded" height="606px" width="100%" />
);

type LibraryFilesTableProps = {
	data: LibraryFile[];
};

const formatDate = (date: string) => {
	return format(new Date(date), 'MMM dd, yyyy');
};

export const LibraryFilesTable = ({ data }: LibraryFilesTableProps) => {
	const table = useReactTable({ columns, data, getCoreRowModel: getCoreRowModel() });

	return (
		<table className={libraryTable}>
			<thead>
				{table.getHeaderGroups().map((headerGroup) => (
					<tr key={headerGroup.id}>
						{headerGroup.headers.map((header) => (
							<th key={header.id}>
								{header.isPlaceholder
									? null
									: flexRender(
											header.column.columnDef.header,
											header.getContext()
									  )}
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody>
				{table.getRowModel().rows.map((row) => (
					<tr key={row.id}>
						{row.getVisibleCells().map((cell) => {
							const isName = cell.column.columnDef.id === 'name';
							const isDate = cell.column.columnDef.id === 'createdAt';

							return (
								<td key={cell.id}>
									{isName && (
										<RouterLink
											to={`/library/file/transcript/${cell.row.original.id}`}
											label={cell.getValue() as string}
										/>
									)}
									{isDate && <span>{formatDate(cell.getValue() as string)}</span>}
									{!isName &&
										!isDate &&
										flexRender(cell.column.columnDef.cell, cell.getContext())}
								</td>
							);
						})}
					</tr>
				))}
			</tbody>
		</table>
	);
};
