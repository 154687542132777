import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { VariableSizeList as LazyLoadList } from 'react-window';
import { withContext } from '../../../withContext';
import docPanelMapping from '../../../mapping/docPanelMapping';
import DocRow from './DocRow';
import LazyLoadAutoSizer from 'react-virtualized-auto-sizer';
import { getDocsWrapper } from '../ClusterView/ClusterViewHelpers';

const DocList = (props) => {
	const { searchInfo, basket } = props;
	const { index: databaseIndex } = searchInfo;
	const [documents, setDocuments] = useState({});

	useEffect(() => {
		if (basket.length > 0) {
			setDocuments(getDocsWrapper());
		} else {
			setDocuments(props.document_dom);
		}
	}, [props.document_dom, basket]);

	const lazyLoadListRef = React.useRef({});
	const lazyLoadRowHeights = React.useRef({});

	const getLazyLoadRowHeight = (lazyLoadRowIndex) =>
		lazyLoadRowHeights.current[lazyLoadRowIndex] + 8 || 82;

	const setLazyLoadRowHeight = React.useCallback((lazyLoadRowIndex, size) => {
		lazyLoadRowHeights.current = { ...lazyLoadRowHeights.current, [lazyLoadRowIndex]: size };
		lazyLoadListRef.current.resetAfterIndex(lazyLoadRowIndex);
	}, []);

	return documents ? (
		<div ref={lazyLoadListRef} data-component="DocList">
			<LazyLoadAutoSizer className="doc-list__lazy-loader">
				{({ height, width }) => (
					<LazyLoadList
						height={height}
						itemCount={Object.keys(documents).length}
						itemSize={getLazyLoadRowHeight}
						ref={lazyLoadListRef}
						width={width}
					>
						{({ index, style }) => {
							const [key, vals] = Object.entries(documents)[index];
							return (
								<DocRow
									data={vals.fields}
									searchInfo={searchInfo}
									dataMap={docPanelMapping[databaseIndex]}
									key={key}
									lazyLoadStyle={style}
									lazyLoadRowIndex={index}
									setLazyLoadRowHeight={setLazyLoadRowHeight}
								/>
							);
						}}
					</LazyLoadList>
				)}
			</LazyLoadAutoSizer>
		</div>
	) : (
		<div
			style={{
				height: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			}}
		>
			Loading...
		</div>
	);
};

const mapStateToProps = (state) => {
	const searchResults = state.sub;
	const searchInfo = state.pub;
	const basket = state.cluster_vis.basket;
	const data = state.cluster_vis.data;
	const document_dom = state.sub.search.document_dom;
	return { searchResults, searchInfo, data, basket, document_dom };
};

const DocListContainer = withContext(connect(mapStateToProps, null)(DocList));
export default DocListContainer;
