import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { theme } from 'cymantic-ui/dist/theme';
import { CenterContent } from 'cymantic-ui/dist/atomic-components/center-content';
import { Text } from 'cymantic-ui/dist/atomic-components/text';
import { Link, RouterLink } from 'cymantic-ui/dist/atomic-components/link';
import { connect } from 'react-redux';

import { withContext } from '../../withContext';

import SearchForm from '../../components/SearchForm/SearchForm';
import { ShuffleHeader } from '../../components/ShuffleHeader/ShuffleHeader';
import { CenteredLayoutContainer } from '../../components/CenteredLayout/centered-layout';
import { HomePageAuthRedirect } from '../../components/Auth/HomePageAuthRedirect';
import { checkTokenExists, isAuthOff } from '../../components/Auth/CognitoHelpers';
import { getShannonLink } from '../../utilities/get-shannon-link';

const HomePage = ({ submitForm }) => {
	// useLocaton is used to force a rerender when route to the page which includes this component.
	const location = useLocation();
	const [isSignedIn, setIsSignedIn] = React.useState(false);
	useEffect(() => {
		if (isAuthOff()) {
			setIsSignedIn(true);
		} else {
			const signedIn = checkTokenExists();
			setIsSignedIn(signedIn);
		}
	}, [location]);

	const shannonLink = getShannonLink();

	return (
		<CenterContent>
			<CenteredLayoutContainer>
				<ShuffleHeader />
				{isSignedIn ? (
					<>
						<SearchForm ClickSubmit={submitForm} />
						<CenterContent>
							{/* This is temporary, hence inline styles */}
							<div
								style={{
									background: theme.color.grey200,
									padding: `${theme.space.space8} ${theme.space.space16}`,
									borderRadius: theme.borderRadius.radiusMD
								}}
							>
								<Text variant="bodyXS">
									To view full <Link href={shannonLink} label="Shannon" isBlank />{' '}
									meeting transcripts, go to your{' '}
									<RouterLink to="/library" label="library" />.
								</Text>
							</div>
						</CenterContent>
					</>
				) : (
					<HomePageAuthRedirect />
				)}
			</CenteredLayoutContainer>
		</CenterContent>
	);
};

const mapStateToProps = (state) => ({
	showResults: state.container.showResults
});

const HomePageContainer = withContext(connect(mapStateToProps, null)(HomePage));
export default HomePageContainer;
