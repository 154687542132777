import { useParams, Navigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import { useClientQuery } from '../../../hooks/use-client-query';
import { LibraryTransition } from '../LibraryTransition';

import { LibraryLayout, LibraryLayoutHeader } from '../LibraryLayout';
import { Transcript } from './TranscriptFilePage.definitions';

import {
	TranscriptFileContent,
	TranscriptFileError,
	TranscriptFileSkeleton,
	TranscriptNotFound
} from './TranscriptFileStates';

export const TranscriptFilePage = () => {
	const { fileId } = useParams();

	const { data, statusObj } = useClientQuery<string, Transcript>('getFile', fileId);
	const { isSuccess, isLoading, isError, isNotFound, isUnauthorized, isUnreachable } = statusObj;
	const hasData = isSuccess && !!data;
	const hasError = (isError || isUnreachable) && !isNotFound;
	const hasHeader = isLoading || hasData;

	return (
		<LibraryLayout>
			{isUnauthorized && <Navigate to="/signout/expired" />}
			{hasHeader && (
				<LibraryLayoutHeader
					icon="File"
					title={data?.name ?? ' '}
					backToLabel={data?.parent.name ?? ' '}
					backTo={`/library/folder/${data?.parent.id}` ?? ' '}
					isLoading={isLoading}
				/>
			)}
			<AnimatePresence>
				{isLoading && (
					<LibraryTransition key="transcriptSkeleton" hasHeader={hasHeader}>
						<TranscriptFileSkeleton />
					</LibraryTransition>
				)}
				{isNotFound && (
					<LibraryTransition key="transcriptNotFound" hasHeader={hasHeader}>
						<TranscriptNotFound />
					</LibraryTransition>
				)}
				{hasError && (
					<LibraryTransition delay={0.3} key="transcriptError" hasHeader={hasHeader}>
						<TranscriptFileError />
					</LibraryTransition>
				)}
				{hasData && (
					<LibraryTransition delay={0.3} key="transcriptContent" hasHeader={hasHeader}>
						<TranscriptFileContent data={data} statusObj={statusObj} />
					</LibraryTransition>
				)}
			</AnimatePresence>
		</LibraryLayout>
	);
};
