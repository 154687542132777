import React from 'react';

import { motion } from 'framer-motion';
import { Logo, AnimatedMenuIcon } from 'cymantic-ui';
import { LeftSidebarLogoLink, LeftSidebarNavLink, LeftSidebarIconLink } from './LeftSidebarLinks';
import { LeftSidebarListHeader } from './LeftSidebarHeader';
import { getShannonLink } from '../../utilities/get-shannon-link';
import { leftSidebar } from './LeftSidebar.styles';

import {
	expandedWidth,
	duration,
	horizontalPadding,
	horizontalPaddingCollapsed
} from './LeftSidebar.definitions';
import LibraryTree from './LibraryTree/LibraryTree';

export const LeftSidebar = () => {
	const [isExpanded, setIsExpanded] = React.useState(false);
	const shannonLink = getShannonLink();

	return (
		<motion.nav
			className={leftSidebar.root}
			initial={false}
			animate={!isExpanded ? { width: '46px' } : { width: expandedWidth }}
			transition={{ duration, ease: 'easeOut' }}
		>
			<motion.button
				className={leftSidebar.menuButton}
				aria-label={isExpanded ? 'Close navigation' : 'Open navigation'}
				onClick={() => setIsExpanded(!isExpanded)}
				initial={false}
				animate={
					!isExpanded
						? { right: horizontalPaddingCollapsed }
						: { right: horizontalPadding }
				}
				transition={{ duration }}
			>
				<AnimatedMenuIcon isExpanded={isExpanded} />
			</motion.button>
			<div className={isExpanded ? leftSidebar.contents : leftSidebar.contentsHidden}>
				<motion.div
					initial={false}
					className={leftSidebar.contentsAnimWrapper}
					animate={!isExpanded ? { opacity: 0 } : { opacity: 1 }}
					transition={{ duration: duration - 0.2, ease: 'easeOut' }}
				>
					<div className={leftSidebar.contentsInnerWrapper}>
						<a href="/" className={leftSidebar.header}>
							<Logo product="Ruthi" hasText height="2.5rem" width="6rem" />
						</a>
						<div className={leftSidebar.listWrapper}>
							<ul className={leftSidebar.list}>
								<li className={leftSidebar.listItem}>
									<LeftSidebarListHeader label="Cymantix" />

									<ul className={leftSidebar.subList}>
										<li>
											<LeftSidebarLogoLink
												href={shannonLink}
												product="Shannon"
											/>
										</li>
									</ul>
								</li>
								<li className={leftSidebar.listItem}>
									<LeftSidebarListHeader label="Dashboard" />

									<ul className={leftSidebar.subList}>
										<li>
											<LeftSidebarNavLink
												to="/results"
												label="Search"
												icon="Search"
											/>
										</li>
									</ul>
								</li>

								<li className={leftSidebar.listItem}>
									<LeftSidebarListHeader label="Files and Assets" />

									<ul className={leftSidebar.subList}>
										<li>
											<LibraryTree />
										</li>
										{/* 
										<li>
											<LeftSidebarNavLink
												to="/favorites"
												label="Favorites"
												icon="Heart"
											/>
										</li>

										<li>
											<LeftSidebarNavLink
												to="/trash"
												label="Trash"
												icon="Trash"
											/>
										</li> */}
									</ul>
								</li>
							</ul>
							<LeftSidebarIconLink
								icon="HelpCircle"
								href="mailto:support@cymantix.com"
								label="Help"
								aria-label="Email Support"
							/>
						</div>
					</div>
				</motion.div>
			</div>
		</motion.nav>
	);
};
