import * as React from 'react';

import { container, subContainer } from './centered-layout.styles';

export const CenteredLayoutContainer = ({ children }) => (
	<div className={container}>{children}</div>
);

export const CenteredLayoutSubContainer = ({ children }) => (
	<div className={subContainer}>{children}</div>
);
