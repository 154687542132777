let initialState = {
	showResults: false,
	signingIn: false,
	signingUp: false,
	forgotPass: false,
	userFiles: []
};

const container = (state = initialState, action) => {
	switch (action.type) {
		// Container holds state that determine which page to display (Home, Results, Sign In, Sign Up, Forgot Password)
		case 'TOGGLE_SHOW_RESULTS': {
			const showResults = action.payload;
			if (showResults === false) {
				const newState = Object.assign({}, { ...state }, { showResults: true });
				return newState;
			} else {
				return state;
			}
		}
		case 'TOGGLE_SHOW_PROFILE_HOVER': {
			const showProfileHover = action.payload;

			const newState = Object.assign(
				{},
				{ ...state },
				{ showProfileHover: showProfileHover }
			);
			return newState;
		}
		// Auth
		case 'TOGGLE_SIGNING_IN': {
			const signingIn = action.payload.data;

			const newState = Object.assign({}, { ...state }, { signingIn: signingIn });
			return newState;
		}
		case 'TOGGLE_SIGNING_UP': {
			const signingUp = action.payload.data;

			return { ...state, signingUp: signingUp };
		}
		case 'TOGGLE_FORGOT_PASS': {
			const forgotPass = action.payload.data;

			return { ...state, forgotPass: forgotPass };
		}
		case 'SET_USER_FILES': {
			const userFiles = action.payload.data;
			const newState = Object.assign({}, { ...state }, { userFiles: userFiles });
			return newState;
		}
		case 'SET_USER_FOLDERS': {
			const userFolders = action.payload.data;
			const newState = Object.assign({}, { ...state }, { userFolders: userFolders });
			return newState;
		}
		default:
			return state;
	}
};

export default container;
