import React from 'react';
const Context = React.createContext();
export default Context;

export function withContext(Component) {
	// TODO: revisit how to handle this or disable display-name rule
	return (props) => (
		<Context.Consumer>{(client) => <Component {...props} client={client} />}</Context.Consumer>
	);
}
