import React from 'react';
import { UserMenuWithAuth } from '../../components/Auth/UserMenuWithAuth';
import { MainNav, NavList, NavLink, useMediaQueries, MobileMenu } from 'cymantic-ui';
import { isAuthOff } from '../Auth/CognitoHelpers';

const HeaderHome = () => {
	const { isMobile } = useMediaQueries();
	const isMobileMenu = isMobile;

	return (
		<MainNav product="Ruthi">
			{isMobileMenu ? (
				<MobileMenu>
					<NavLink label="About" href="https://www.cymantix.com" />
					<NavLink label="Contact" href="mailto:support@cymantix.com" />
					{!isAuthOff() ? <UserMenuWithAuth /> : null}
				</MobileMenu>
			) : (
				<NavList gap="space48">
					<NavLink label="About" href="https://www.cymantix.com" />
					<NavLink label="Contact" href="mailto:support@cymantix.com" />
					{!isAuthOff() ? <UserMenuWithAuth /> : null}
				</NavList>
			)}
		</MainNav>
	);
};

export default HeaderHome;
