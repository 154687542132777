import * as React from 'react';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import { UserMenu } from 'cymantic-ui/dist/molecular-components/user-menu';
import { NavButton } from 'cymantic-ui/dist/atomic-components/nav';
import { DropdownButton } from 'cymantic-ui/dist/atomic-components/dropdown';

import * as CognitoHelpers from './CognitoHelpers';
import { userMenu } from './UserMenuWithAuth.styles';

export const UserMenuWithAuth = () => {
	// useLocaton is used to force a rerender when route to the page which includes this component.
	const location = useLocation();
	const hasSignedIn = Cookies.get('hasSignedIn') === 'true';
	const [isSignedIn, setIsSignedIn] = React.useState(false);
	const [tokenUserData, setTokenUserData] = React.useState({ name: '', email: '' });

	React.useEffect(() => {
		const signedIn = CognitoHelpers.checkTokenExists();
		setIsSignedIn(signedIn);
		if (signedIn) {
			const dataFromToken = CognitoHelpers.getUserDataFromToken();
			setTokenUserData(dataFromToken); // Setting the data retrieved from token
		}
	}, [location]);

	return (
		<div className={userMenu}>
			{isSignedIn && (
				<UserMenu
					isSignedIn={isSignedIn}
					handleSignIn={CognitoHelpers.handleRedirectToSignIn}
					handleSignOut={CognitoHelpers.handleRedirectToSignOut}
					userName={tokenUserData.name}
					userEmail={tokenUserData.email}
				>
					<DropdownButton
						label="Sign Out"
						onClick={CognitoHelpers.handleRedirectToSignOut}
					/>
				</UserMenu>
			)}
			{!isSignedIn && !hasSignedIn && (
				<NavButton label="Sign Up" onClick={CognitoHelpers.handleRedirectToSignUp} />
			)}
			{!isSignedIn && hasSignedIn && (
				<NavButton label="Sign In" onClick={CognitoHelpers.handleRedirectToSignIn} />
			)}
		</div>
	);
};
