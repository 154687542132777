import { css } from '@emotion/css';
import { theme, mq } from 'cymantic-ui';

export const container = css({
	display: 'flex',
	flexDirection: 'column',
	minHeight: '100vh',
	height: '100%'
});

export const content = css`
	flex: 1;
	padding: 5.625rem ${theme.space.space32} ${theme.space.space32} ${theme.space.space32};

	${mq.tabletLarge} {
		padding: 5.625rem ${theme.space.space64} ${theme.space.space64} ${theme.space.space64};
	}
`;
