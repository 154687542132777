import { theme } from 'cymantic-ui/dist/theme';
import { css } from '@emotion/css';

import { horizontalPadding, expandedWidth } from './LeftSidebar.definitions';

const verticalPadding = theme.space.space32;

export const leftSidebar = {
	root: css`
		position: relative;
		background-color: ${theme.color.white};
		border-right: 2px solid ${theme.color.grey200};
		display: flex;
		flex-direction: column;
		height: 100vh;
	`,
	menuButton: css`
		width: ${theme.space.space20};
		height: ${theme.space.space20};
		position: absolute;
		z-index: 100;
		right: ${horizontalPadding};
		top: ${verticalPadding};

		:after {
			position: absolute;
			height: 9rem;
			width: ${theme.space.space40};
			content: ' ';
			top: -4rem;
			left: -50%;
			right: -50%;
		}
	`,
	contents: css`
		position: relative;
		width: ${expandedWidth};
		height: 100vh;
	`,
	contentsHidden: css`
		position: relative;
		display: none;
	`,
	contentsAnimWrapper: css`
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: ${expandedWidth};
		overflow: hidden;
	`,
	contentsInnerWrapper: css`
		height: 100%;

		/* overflow: hidden;
		display: flex;
		flex-direction: column; */
	`,
	header: css`
		margin: ${verticalPadding} 0 ${verticalPadding} ${horizontalPadding};
		display: block;
		/* width: min-content; */
		/* height: 6.5rem; */
	`,
	listWrapper: css`
		overflow-y: auto;
		height: calc(100vh - 6.5rem);
		display: flex;
		flex-direction: column;
		gap: ${theme.space.space24};
		justify-content: space-between;
		padding-bottom: ${theme.space.space8};
	`,
	list: css`
		display: flex;
		flex-direction: column;
		gap: ${theme.space.space24};
	`,
	listItem: css`
		/* display: flex;
		flex-direction: column;
		gap: ${theme.space.space8}; */
	`,
	subList: css`
		/* display: flex;
		flex-direction: column; */
	`
};

export const leftSidebarListHeader = css`
	padding: 0 ${horizontalPadding};
`;
