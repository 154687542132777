import React from 'react';
import { Link } from 'cymantic-ui';

/**
 * Formats a message by replacing occurrences of "contact us" with a mailto link.
 *
 * @param {string} text - The input message string to be formatted.
 * @returns {JSX.Element} The formatted message JSX element.
 */
const formatContactUs = (text) => {
	// Create a regular expression to find occurrences of "contact us" (case-insensitive).
	const contactUsRegex = /contact us/gi;
	// Get all matches of the regular expression in the input text for use in Link label.
	// Handles case-sensitive issues, e.g., Contact us is at the beginning of the text.
	const contactUsMatches = text.match(contactUsRegex);

	// Split the string by the "contact us" regular expression and store it in parts.
	const parts = text.split(contactUsRegex);
	// Create a new array to hold the formatted parts of the message.
	const formattedParts = parts.map((part, index) =>
		index < parts.length - 1 ? (
			<React.Fragment key={index}>
				{part}
				<Link label={contactUsMatches[index]} href="mailto:support@cymantix.com" />
			</React.Fragment>
		) : (
			<React.Fragment key={index}>{part}</React.Fragment>
		)
	);

	// Return the formatted message JSX element.
	return formattedParts;
};

export default formatContactUs;
