import { CenterContent } from 'cymantic-ui/dist/atomic-components/center-content';
import { RouterLink } from 'cymantic-ui/dist/atomic-components/link';
import { SkeletonAreaLoader } from 'cymantic-ui/dist/atomic-components/skeleton-loader';
import { Text } from 'cymantic-ui/dist/atomic-components/text';

import { FolderLink } from '../../../components/FolderLink/FolderLink';
import { PageSection } from '../../../components/PageSection/PageSection';
import { PageNotFound } from '../../../components/PageNotFound/PageNotFound';
import {
	CenteredLayoutContainer,
	CenteredLayoutSubContainer
} from '../../../components/CenteredLayout/centered-layout';

import { LibraryFolderRow, LibraryLayoutContent } from '../LibraryLayout';
import { FolderInfo } from '../Library.definitions';
import { LibraryTable } from '../LibraryTable/LibraryTable';
import { LibraryTableNoFiles, LibraryTableSkeleton } from '../LibraryTable/LibraryTableStates';

export const LibraryFolderSkeleton = () => (
	<LibraryLayoutContent>
		<PageSection title=" " isLoading>
			<LibraryFolderRow>
				<SkeletonAreaLoader height="46px" width="120px" />
				<SkeletonAreaLoader height="46px" width="120px" />
				<SkeletonAreaLoader height="46px" width="120px" />
			</LibraryFolderRow>
		</PageSection>
		<PageSection title=" " isLoading>
			<LibraryTableSkeleton />
		</PageSection>
	</LibraryLayoutContent>
);

export const LibraryFolderNotFound = () => (
	<PageNotFound
		title="Folder not found"
		subtitle="The folder you are looking for doesn’t exist or has been moved."
	>
		<RouterLink to="/library" label="Return to Library" iconLeft="ArrowLeft" />
	</PageNotFound>
);

export const LibraryFolderEmpty = () => (
	<CenterContent isFullHeight>
		<CenteredLayoutContainer>
			<CenteredLayoutSubContainer>
				<Text tag="h1" variant="headerH4" textAlign="center">
					You don’t have any Shannon meeting transcripts yet.
				</Text>
				<Text tag="p" variant="bodySM" color="grey700" textAlign="center">
					When meeting transcripts are saved they will appear here.
				</Text>
			</CenteredLayoutSubContainer>
			<CenteredLayoutSubContainer>
				<RouterLink to="/library" iconLeft="ArrowLeft" label="Return to Library" />
			</CenteredLayoutSubContainer>
		</CenteredLayoutContainer>
	</CenterContent>
);

type LibraryFolderContentProps = {
	data: FolderInfo;
	folderId: string;
};

export const LibraryFolderContent = ({ data, folderId }: LibraryFolderContentProps) => {
	const hasSubFolders = !!data && data.subFolders.length > 0;
	const hasFiles = !!data && data?.fileCount >= 1;

	return (
		<LibraryLayoutContent>
			{hasSubFolders && (
				<PageSection title="Folders">
					<LibraryFolderRow>
						{data.subFolders.map((subfolder) => {
							return (
								<FolderLink
									key={subfolder.id}
									label={subfolder.name}
									to={`/library/folder/${subfolder.id}`}
								/>
							);
						})}
					</LibraryFolderRow>
				</PageSection>
			)}
			<PageSection title="Files">
				{hasFiles ? (
					<LibraryTable folderId={folderId || ''} fileCount={data.fileCount} />
				) : (
					<LibraryTableNoFiles />
				)}
			</PageSection>
		</LibraryLayoutContent>
	);
};
