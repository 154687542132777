import * as React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { withContext } from '../withContext';
import CognitoAuth from './Auth/CognitoAuth';

// Routes
import CenteredPageLayout from '../routes/CenteredPageLayout/CenteredPageLayout';
import LeftSidebarPageLayout from '../routes/LeftSidebarPageLayout/LeftSidebarPageLayout';
import HomePage from '../routes/HomePage/HomePage';
import FailedAuthPage from '../routes/FailedAuthPage/FailedAuthPage';
import ResultsPage from '../routes/ResultsPage/ResultsPage';
import LibraryFolderPage from '../routes/Library/LibraryFolderPage/LibraryFolderPage';
import LibraryPage from '../routes/Library/LibraryPage/LibraryPage';
import { TranscriptFilePage } from '../routes/Library/TranscriptFilePage/TranscriptFilePage';
import NoMatchPage from '../routes/NoMatchPage/NoMatchPage';

import Protected from '../routes/Protected/Protected';
import { Terms, PrivacyPolicy } from 'cymantic-ui';

const App = () => (
	<BrowserRouter>
		<CognitoAuth>
			<Routes>
				<Route path="/" element={<LeftSidebarPageLayout />}>
					<Route
						path="/results"
						element={
							<Protected>
								<ResultsPage />
							</Protected>
						}
					/>
					<Route
						path="/library"
						element={
							<Protected>
								<LibraryPage />
							</Protected>
						}
					/>
					<Route
						path="/library/folder/:folderId"
						element={
							<Protected>
								<LibraryFolderPage />
							</Protected>
						}
					/>
					<Route
						path="/library/file/transcript/:fileId"
						element={
							<Protected>
								<TranscriptFilePage />
							</Protected>
						}
					/>
				</Route>
				<Route path="/" element={<CenteredPageLayout />}>
					<Route index element={<HomePage />} />
					<Route path="*" element={<NoMatchPage />} />
				</Route>
				<Route path="/signout/:cause" element={<CenteredPageLayout />}>
					<Route index element={<FailedAuthPage />} />
				</Route>
				<Route path="/terms" element={<CenteredPageLayout />}>
					<Route index element={<Terms product="ruthi" />} />
				</Route>
				<Route path="/privacy" element={<CenteredPageLayout />}>
					<Route index element={<PrivacyPolicy />} />
				</Route>
			</Routes>
		</CognitoAuth>
	</BrowserRouter>
);

const AppContainer = withContext(App);

export default AppContainer;
