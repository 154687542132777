import { css } from '@emotion/css';
import { theme } from 'cymantic-ui/dist/theme';

export const grid = css({
	display: 'grid',
	gridTemplateColumns: 'auto',
	gridTemplateRows: 'auto auto minmax(auto, 1fr)',
	height: '100%',
	overflow: 'hidden',
	width: '100%'
});

export const header = css({
	alignItems: 'center',
	display: 'flex',
	justifyContent: 'space-between',
	padding: `${theme.space.space32} ${theme.space.space32} 0 ${theme.space.space32}`
});

export const headerForm = css({
	alignItems: 'center',
	display: 'flex',
	gap: theme.space.space16
});
