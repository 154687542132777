import { Text, theme } from 'cymantic-ui';
import Relativity from './Relativity';

const RelativityPanel = () => (
	// TODO: Accessibility: format as list, figure out correct heading level
	<div data-component="RelativityPanel">
		<Text tag="h3" variant="bodyTiny" isBold>
			Relevance
		</Text>

		<Relativity
			label="76-100%"
			color="linear-gradient(313.54deg, #3E5CE0 0%, #5A79E8 137.09%)"
			border="none"
		/>
		<Relativity
			label="51-75%"
			color="linear-gradient(314.43deg, #6584EB 0%, #839DF0 77.98%, #839DF0 124.76%)"
			border="none"
		/>
		<Relativity
			label="26-50%"
			color="linear-gradient(317.98deg, #B6C6F6 31.71%, #D3DDFA 132.21%)"
			border="none"
		/>
		<Relativity
			label="< 25%"
			color={theme.color.grey50}
			border={`1px solid ${theme.color.grey200}`}
		/>
	</div>
);

export default RelativityPanel;
