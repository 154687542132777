import * as React from 'react';

import { Icon, Text, CenterContent } from 'cymantic-ui/dist/atomic-components';

import {
	CenteredLayoutContainer,
	CenteredLayoutSubContainer
} from '../CenteredLayout/centered-layout';

type PageNotFoundProps = {
	title: string;
	subtitle: string;
	children?: React.ReactNode;
};

export const PageNotFound = ({ title, subtitle, children }: PageNotFoundProps) => (
	<CenterContent isFullHeight>
		<CenteredLayoutContainer>
			<CenteredLayoutSubContainer>
				<Icon icon="Search" color="grey400" size="space48" />
				<Text tag="h1" variant="headerH4" textAlign="center">
					{title}
				</Text>
				<Text tag="p" variant="bodySM" color="grey700" textAlign="center">
					{subtitle}
				</Text>
			</CenteredLayoutSubContainer>
			<CenteredLayoutSubContainer>{children}</CenteredLayoutSubContainer>
		</CenteredLayoutContainer>
	</CenterContent>
);
