import { configureStore } from '@reduxjs/toolkit';

import reducers from './redux/reducers';

const store = configureStore({ reducer: reducers });

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type
export type AppDispatch = typeof store.dispatch;
