import * as React from 'react';
import * as stateActions from '../../../redux/stateActions';
import { connect } from 'react-redux';
import { withContext } from '../../../withContext';
import { Tree, TreeProps } from './Tree/Tree';
import { INode, MutableTreeState } from './Tree/Tree.definitions';
import { useClientQuery } from '../../../hooks/use-client-query';

function LibraryTree({
	userFolders,
	setUserFolders
}: {
	userFolders: INode[];
	setUserFolders: (userFolders: INode[]) => void;
}) {
	useClientQuery<undefined, INode[]>('getAllFolders', undefined, setUserFolders);

	const root: INode[] = [
		{
			id: '0',
			// Root folder is always "Library"
			name: 'Library',
			items: userFolders
		}
	];

	// Retrieves which nodes are expanded and selected from local storage
	const treeState: TreeProps['treeState'] = React.useMemo(() => {
		const localStorageTreeState = localStorage.getItem('tree-state');
		const tree: MutableTreeState | null = localStorageTreeState
			? JSON.parse(localStorageTreeState)
			: null;

		if (!tree) {
			return { selected: [0], expanded: [] };
		}

		return {
			selected: Array.isArray(tree.selected) ? tree.selected : tree.selected.path,
			expanded: tree.expanded
		};
	}, []);

	// Sets which nodes are expanded and selected in local storage
	const persistTreeState: TreeProps['persistTreeState'] = React.useCallback((state) => {
		const { expanded, selected } = state;

		localStorage.setItem(
			'tree-state',
			JSON.stringify({
				expanded,
				selected: Array.isArray(selected) ? selected : selected.path
			})
		);
	}, []);

	return <Tree root={root} treeState={treeState} persistTreeState={persistTreeState} />;
}

// TODO: Type state object
const mapStateToProps = (state: any) => {
	const { userFolders } = state.container;
	return { userFolders: userFolders as INode[] };
};

const mapDispatchToProps = (dispatch: any) => ({
	setUserFolders: (userFolders: INode[]) => dispatch(stateActions.setUserFolders(userFolders))
});

const LibraryTreeContainer = withContext(connect(mapStateToProps, mapDispatchToProps)(LibraryTree));

export default LibraryTreeContainer;
