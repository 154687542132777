import React from 'react';
import { Text, RouterLink } from 'cymantic-ui';
import { footer } from './Footer.styles';

const Footer = () => {
	const yearOfCopyright = new Date().getFullYear();

	return (
		<div className={footer}>
			<Text variant="bodyXXS" color="grey500" tag="div">
				Copyright &copy; {yearOfCopyright} Cymantix Inc
			</Text>
			<Text variant="bodyXXS" color="grey500" tag="div">
				<RouterLink label="Terms of Use" to="/terms" /> &amp;{' '}
				<RouterLink label="Privacy" to="/privacy" />
			</Text>
		</div>
	);
};

export default Footer;
