import { fromUnixTime, intervalToDuration } from 'date-fns';

/**
 * Uses Intl.DateTimeFormat to get the timezone abbreviation, this is a native function in most browsers
 * @returns Timezone abbreviation (ex: 'PST')
 */
export function getTimezoneAbbreviation(): string {
	// If Intl is not supported, return an empty string
	if (typeof Intl === 'undefined') {
		return '';
	}

	// Get the current date
	const now = new Date();

	// Get the time zone abbreviation using Intl.DateTimeFormat
	const timezoneAbbreviation = (
		Intl.DateTimeFormat('en', { timeZoneName: 'short' })
			.formatToParts(now)
			.find((part) => part.type === 'timeZoneName') || { value: '' }
	).value;

	return timezoneAbbreviation;
}

/**
 * Gets the duration between two timestamps
 * @param firstTimestamp First timestamp
 * @param lastTimestamp Last timestamp
 * @returns Object with hours, minutes, and seconds
 */
export const getDurationFromUnix = (firstTimestamp: number, lastTimestamp: number) => {
	const firstTimeFromUnix = fromUnixTime(firstTimestamp / 1000);
	const lastTimeFromUnix = fromUnixTime(lastTimestamp / 1000);

	const { hours, minutes, seconds } = intervalToDuration({
		start: firstTimeFromUnix,
		end: lastTimeFromUnix
	});

	return { hours, minutes, seconds };
};
