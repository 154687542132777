import * as React from 'react';
import { CenterContent, Text, RouterLink } from 'cymantic-ui';
import {
	CenteredLayoutContainer,
	CenteredLayoutSubContainer
} from '../../components/CenteredLayout/centered-layout';

const NoMatchPage = () => (
	<CenterContent>
		<CenteredLayoutContainer>
			<CenteredLayoutSubContainer>
				<Text tag="h1" variant="headerH1" textAlign="center">
					Oops!
				</Text>
				<Text tag="p" variant="bodyM" color="grey700" textAlign="center">
					This page does not exist
				</Text>
			</CenteredLayoutSubContainer>
			<CenteredLayoutSubContainer>
				<Text tag="p" variant="bodySM" color="grey700" textAlign="center">
					Return to <RouterLink to="/" label="Homepage" />.
				</Text>
			</CenteredLayoutSubContainer>
		</CenteredLayoutContainer>
	</CenterContent>
);

export default NoMatchPage;
