import * as React from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { withContext } from '../../withContext';
import * as stateActions from '../../redux/stateActions';
import { CenterContent, Text } from 'cymantic-ui';
import {
	CenteredLayoutContainer,
	CenteredLayoutSubContainer
} from '../../components/CenteredLayout/centered-layout';
import { HomePageAuthRedirect } from '../../components/Auth/HomePageAuthRedirect';
import { handleSignOutFlow } from '../../components/Auth/CognitoHelpers';
import { FailedAuthPageProps, UserData } from './FailedAuthPage.definitions';

const FailedAuthPage = ({ setUserData }: FailedAuthPageProps) => {
	const navigate = useNavigate();
	const { cause } = useParams();

	React.useEffect(() => {
		if (!cause) {
			navigate('/');
		}
		handleSignOutFlow(setUserData);
	}, [setUserData, navigate, cause]);

	return (
		<CenterContent>
			<CenteredLayoutContainer>
				<CenteredLayoutSubContainer>
					<Text tag="h1" variant="headerH3" textAlign="center">
						{cause === 'expired' && 'Your session has expired'}
						{cause === 'invalid' && 'Your session authentication was not valid'}
						{cause === 'unknown' && 'Your session failed to authenticate'}
					</Text>
					<Text tag="p" color="grey600" textAlign="center">
						Please sign in to continue exploring your data.
					</Text>
				</CenteredLayoutSubContainer>
				<HomePageAuthRedirect />
			</CenteredLayoutContainer>
		</CenterContent>
	);
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		setUserData: (userData: UserData) => dispatch(stateActions.setUserData(userData))
	};
};

const FailedAuthPageContainer = withContext(connect(null, mapDispatchToProps)(FailedAuthPage));

export default FailedAuthPageContainer;
