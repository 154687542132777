import { css } from '@emotion/css';
import { theme } from 'cymantic-ui/dist/theme';

export const loaderWrapper = css`
	display: flex;
	flex-direction: column;
	gap: ${theme.space.space16};
	justify-content: center;
	align-items: center;
`;
