import { UserMenuWithAuth } from '../../components/Auth/UserMenuWithAuth';
import {
	IconName,
	Text,
	Icon,
	RouterLink,
	SkeletonTextLoader,
	SkeletonAreaLoader
} from 'cymantic-ui/dist/atomic-components';
import {
	libraryFolderRow,
	libraryLayout,
	libraryLayoutContent,
	libraryLayoutHeader
} from './LibraryLayout.styles';
import { AnimatePresence } from 'framer-motion';
import { LibraryTransition } from './LibraryTransition';

type LibraryLayoutProps = {
	children?: React.ReactNode;
};

export const LibraryLayout = ({ children }: LibraryLayoutProps) => (
	<div className={libraryLayout}>{children}</div>
);

type LibraryLayoutHeaderProps = {
	backToLabel?: string;
	icon?: IconName;
	title: string;
	backTo?: string;
	isLoading?: boolean;
};

export const LibraryLayoutHeader = ({
	title,
	backToLabel,
	backTo,
	icon,
	isLoading
}: LibraryLayoutHeaderProps) => {
	const hasBackLink = !!backTo && !!backToLabel;

	return (
		<header className={libraryLayoutHeader.root}>
			<AnimatePresence>
				{isLoading && (
					<LibraryTransition key="headerLoader" className={libraryLayoutHeader.left}>
						{hasBackLink && <SkeletonTextLoader variant="bodyXXS" width="100px" />}
						<div className={libraryLayoutHeader.title}>
							{icon && <SkeletonAreaLoader variant="circular" width="20px" />}
							<SkeletonTextLoader variant="headerH4" width="200px" />
						</div>
					</LibraryTransition>
				)}
				{!isLoading && (
					<LibraryTransition
						key="headerContent"
						delay={0.3}
						className={libraryLayoutHeader.left}
					>
						{hasBackLink && (
							<RouterLink
								variant="bodyXXS"
								to={backTo}
								label={backToLabel}
								iconLeft="CornerLeftUp"
							/>
						)}
						<div className={libraryLayoutHeader.title}>
							{icon && <Icon icon={icon} size="space20" color="grey500" />}
							<Text tag="h1" variant="headerH4">
								{title}
							</Text>
						</div>
					</LibraryTransition>
				)}
			</AnimatePresence>
			<div className={libraryLayoutHeader.right}>
				<UserMenuWithAuth />
			</div>
		</header>
	);
};

type LibraryLayoutContentProps = {
	children?: React.ReactNode;
};

export const LibraryLayoutContent = ({ children }: LibraryLayoutContentProps) => (
	<main className={libraryLayoutContent}>{children}</main>
);

type LibraryFoldersRow = {
	children?: React.ReactNode;
};

export const LibraryFolderRow = ({ children }: LibraryFoldersRow) => (
	<div className={libraryFolderRow}>{children}</div>
);
