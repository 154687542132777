import { theme } from 'cymantic-ui/dist/theme';
import { css } from '@emotion/css';

export const pageSectionError = {
	root: css`
		align-items: center;
		background-color: ${theme.color.grey100};
		border-radius: ${theme.borderRadius.radiusMD};
		border: 1px solid ${theme.color.grey300};
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: 200px;
		padding: ${theme.space.space24};
		gap: ${theme.space.space32};
	`,
	title: css`
		align-items: center;
		display: flex;
		flex-direction: column;
		gap: ${theme.space.space16};
		justify-content: center;
	`
};
