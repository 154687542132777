import * as React from 'react';
import * as stateActions from '../../redux/stateActions';
import Cookies from 'js-cookie';

import { connect } from 'react-redux';
import { withContext } from '../../withContext';
import {
	retrieveAuthCodeFromUrl,
	handleSignInFlow,
	handleSignOutFlow,
	checkTokenExists,
	checkIfTokenIsValid,
	isAuthOff
} from './CognitoHelpers';
import { useNavigate } from 'react-router-dom';
import { logError } from '../../utilities/logger';
import { CenterContent, Text, theme } from 'cymantic-ui';
import { BeatLoader } from 'react-spinners';
import { loaderWrapper } from './CognitoAuth.styles';

const CognitoAuth = ({ setUserData, children }) => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = React.useState(false);

	React.useEffect(() => {
		// This wrapper function is required due to useEffect's constarint.
		// For more info : https://devtrium.com/posts/async-functions-useeffect
		const executeTokenValidityCheck = async () => {
			return await checkIfTokenIsValid();
		};
		const executeHandleSignInFlow = async (setUserData) => {
			return await handleSignInFlow(setUserData);
		};

		if (isLoading) {
			return;
		}

		// 1. Handle sign in and sign out flow
		const authCode = retrieveAuthCodeFromUrl();
		const isRedirectedFromSignIn = !!authCode;
		const isRedirectedFromSignOut = Cookies.get('CognitoSigningOutRedirection') === 'true';

		if (isRedirectedFromSignIn) {
			setIsLoading(true);
			executeHandleSignInFlow(setUserData)
				.then(() => {
					setIsLoading(false);
					navigate('/');
				})
				.catch((error) => {
					setIsLoading(false);
					logError('error signing in: ', error);
					navigate('/signout/invalid');
				});
			return;
		} else if (isRedirectedFromSignOut) {
			handleSignOutFlow(setUserData);
			return;
		}

		// 2. Handle already signed-in flow
		const isTokenExists = checkTokenExists();

		if (isTokenExists && !isAuthOff()) {
			// TODO : need to decide to show loading UI or not
			executeTokenValidityCheck()
				.then((validToken) => {
					if (!validToken) {
						logError('token is invalid');
						handleSignOutFlow(setUserData);
						navigate('/signout/invalid');
					}
				})
				.catch(() => {
					logError('error occurred while checking token validity');
					handleSignOutFlow(setUserData);
					navigate('/signout/unknown');
				});
		}
	}, [isLoading, navigate, setUserData]); // useEffect should run every rendering

	return isLoading ? (
		<CenterContent>
			<div className={loaderWrapper}>
				<Text fontStyle="italic" color="grey500">
					Signing in...
				</Text>
				<BeatLoader size={15} color={theme.color.primary900} loading />
			</div>
		</CenterContent>
	) : (
		children
	);
};

const mapDispatchToProps = (dispatch) => ({
	setUserData: (userData) => dispatch(stateActions.setUserData(userData))
});

const CognitoAuthContainer = withContext(connect(null, mapDispatchToProps)(CognitoAuth));

export default CognitoAuthContainer;
