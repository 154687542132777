import * as React from 'react';
import Context from '../withContext';
import { useNavigate } from 'react-router-dom';
import { StatusObj } from '../utilities/get-status';

/**
 * useClientQuery: Standardizes the way we make queries through client
 * @use useClientQuery<InputType, OutputType>(query, variables, callback)
 * @param query: String (name of async function in client)
 * @param variables: InputType - object of input variables or undefined
 * @param callback: (data: OutputType) => void - use for dispatches etc
 * @returns OutputType | undefined
 */
export function useClientQuery<InputType, OutputType>(
	query: string,
	variables?: InputType,
	callback?: (data: OutputType) => void
): { data?: OutputType; statusObj: StatusObj } {
	const navigate = useNavigate();
	const client = React.useContext(Context);
	const [returnedData, setReturnedData] = React.useState<OutputType>();
	const [status, setStatus] = React.useState<StatusObj>({
		isLoading: true,
		isSuccess: false,
		isError: false,
		isUnreachable: false,
		isErrorNoResults: false,
		isUnauthorized: false,
		isNotFound: false
	});

	React.useEffect(() => {
		const getQuery = async () => {
			const response = await client[query](variables ?? undefined);

			if (response?.data) {
				setReturnedData(response.data);
			}

			if (response?.statusObj) {
				setStatus(response.statusObj);
			}

			if (callback && response?.data) {
				callback(response.data);
			}
		};
		getQuery();
	}, [query, variables, client, navigate, callback]);

	return { data: returnedData || undefined, statusObj: status };
}
