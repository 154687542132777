import { createColumnHelper } from '@tanstack/react-table';

export type LibraryFile = {
	id: string;
	name: string;
	createdAt: string;
	type?: string;
};

const columnHelper = createColumnHelper<LibraryFile>();

export const columns = [
	columnHelper.accessor('name', {
		id: 'name',
		cell: (info) => info.getValue(),
		header: () => 'Name'
	}),
	columnHelper.accessor('createdAt', {
		id: 'createdAt',
		cell: (info) => info.getValue(),
		header: () => 'Date Created'
	})
];

export const exampleData: LibraryFile[] = [
	{
		id: '1',
		name: 'UX Meeting',
		createdAt: 'Jan 08, 2023',
		type: 'transcript'
	},
	{
		id: '2',
		name: 'Sprint Review',
		createdAt: 'Dec 25, 2022',
		type: 'transcript'
	},
	{
		id: '3',
		name: '1 on 1 with Dana',
		createdAt: 'Dec 2, 2022',
		type: 'transcript'
	},
	{
		id: '4',
		name: '1 on 1 with John',
		createdAt: 'Dec 1, 2022',
		type: 'transcript'
	},
	{
		id: '5',
		name: 'All Hands Meeting - November',
		createdAt: 'Nov 30, 2022',
		type: 'transcript'
	},
	{
		id: '6',
		name: 'Sprint Planning',
		createdAt: 'Nov 15, 2022',
		type: 'transcript'
	},
	{
		id: '7',
		name: 'All Hands Meeting - October',
		createdAt: 'Oct 30, 2022',
		type: 'transcript'
	},
	{
		id: '8',
		name: 'Sprint Review',
		createdAt: 'Oct 25, 2022',
		type: 'transcript'
	},
	{
		id: '9',
		name: '1 on 1 with Dana',
		createdAt: 'Oct 15, 2022',
		type: 'transcript'
	},
	{
		id: '10',
		name: 'Design Review',
		createdAt: 'Oct 10, 2022',
		type: 'transcript'
	},
	{
		id: '11',
		name: 'Stakeholder Meeting - September',
		createdAt: 'Sep 30, 2022',
		type: 'transcript'
	},
	{
		id: '12',
		name: 'Sprint Planning',
		createdAt: 'Sep 15, 2022',
		type: 'transcript'
	},
	{
		id: '13',
		name: '1 on 1 with Dana',
		createdAt: 'Sep 10, 2022',
		type: 'transcript'
	},
	{
		id: '14',
		name: 'All Hands Meeting - August',
		createdAt: 'Aug 30, 2022',
		type: 'transcript'
	},
	{
		id: '15',
		name: 'Sprint Review',
		createdAt: 'Aug 25, 2022',
		type: 'transcript'
	},
	{
		id: '16',
		name: '1 on 1 with Dana',
		createdAt: 'Aug 10, 2022',
		type: 'transcript'
	},
	{
		id: '17',
		name: 'All Hands Meeting - July',
		createdAt: 'Jul 30, 2022',
		type: 'transcript'
	},
	{
		id: '18',
		name: 'Sprint Planning',
		createdAt: 'Jul 15, 2022',
		type: 'transcript'
	},
	{
		id: '19',
		name: '1 on 1 with Dana',
		createdAt: 'Jul 10, 2022',
		type: 'transcript'
	},
	{
		id: '20',
		name: 'Stakeholder Meeting - June',
		createdAt: 'Jun 30, 2022',
		type: 'transcript'
	},
	{ id: '21', name: 'Sprint Review', createdAt: 'Jun 25, 2022', type: 'transcript' },
	{ id: '22', name: '1 on 1 with Dana', createdAt: 'Jun 10, 2022', type: 'transcript' },
	{ id: '23', name: 'All Hands Meeting - May', createdAt: 'May 30, 2022', type: 'transcript' },
	{ id: '24', name: 'Sprint Planning', createdAt: 'May 15, 2022', type: 'transcript' },
	{ id: '25', name: '1 on 1 with Dana', createdAt: 'May 10, 2022', type: 'transcript' },
	{ id: '26', name: 'All Hands Meeting - April', createdAt: 'Apr 30, 2022', type: 'transcript' },
	{ id: '27', name: 'Sprint Review', createdAt: 'Apr 25, 2022', type: 'transcript' },
	{ id: '28', name: '1 on 1 with Dana', createdAt: 'Apr 10, 2022', type: 'transcript' },
	{ id: '29', name: 'All Hands Meeting - March', createdAt: 'Mar 30, 2022', type: 'transcript' },
	{ id: '30', name: 'Sprint Planning', createdAt: 'Mar 15, 2022', type: 'transcript' },
	{ id: '31', name: '1 on 1 with Dana', createdAt: 'Mar 10, 2022', type: 'transcript' },
	{
		id: '32',
		name: 'Stakeholder Meeting - February',
		createdAt: 'Feb 30, 2022',
		type: 'transcript'
	},
	{ id: '33', name: 'Sprint Review', createdAt: 'Feb 25, 2022', type: 'transcript' },
	{ id: '34', name: '1 on 1 with Dana', createdAt: 'Feb 10, 2022', type: 'transcript' },
	{
		id: '35',
		name: 'All Hands Meeting - January',
		createdAt: 'Jan 30, 2022',
		type: 'transcript'
	},
	{ id: '36', name: 'Sprint Planning', createdAt: 'Jan 15, 2022', type: 'transcript' },
	{ id: '37', name: '1 on 1 with Dana', createdAt: 'Jan 10, 2022', type: 'transcript' },
	{
		id: '38',
		name: 'Stakeholder Meeting - December',
		createdAt: 'Dec 30, 2021',
		type: 'transcript'
	},
	{ id: '39', name: 'Sprint Review', createdAt: 'Dec 25, 2021', type: 'transcript' },
	{ id: '40', name: '1 on 1 with Dana', createdAt: 'Dec 10, 2021', type: 'transcript' }
];
