import { BeatLoader } from 'react-spinners';
import { connect } from 'react-redux';
import { getStatus } from '../../../utilities/get-status';
import { useImperativeHandle } from 'react';
import * as React from 'react';
import * as stateActions from '../../../redux/stateActions';
import Context from '../../../withContext';
import formatContactUs from '../../../utilities/format-contact-us';

/**
 * ClusterView helpers
 */
import { ClusterSVGSprites } from './ClusterSVGSprites';
import {
	formatD3ClusterData,
	resetClusterView,
	getFilteredData,
	buildAllMemberDocs
} from './ClusterViewHelpers';
import { renderClusterView } from './ClusterViewD3Renderers';
import { zoomInController, zoomOutController } from './ClusterViewControllers';

import {
	CenterContent,
	InlineAlert,
	Text,
	Link,
	RouterLink,
	Button
} from 'cymantic-ui/dist/atomic-components';
import { useNavigate } from 'react-router-dom';

const ClusterView = React.forwardRef(
	(
		{
			data,
			basket,
			setData,
			setSelectedMemberDocs,
			searchResults,
			query,
			index,
			activeDatabase
		},
		ref
	) => {
		const { cluster_dom, document_dom, code, userMessage, userMessageTitle } =
			searchResults.search;
		const Client = React.useContext(Context);
		const svgRef = React.useRef();
		const navigate = useNavigate();
		const { isError, isSuccess, isUnreachable, isLoading, isErrorNoResults, isUnauthorized } =
			getStatus(code);

		const hasClustersAndDocuments =
			cluster_dom && document_dom && Object.keys(document_dom).length > 0;
		const hasDocumentsNoClusters =
			!cluster_dom && document_dom && Object.keys(document_dom).length > 0;
		const hasNoClustersAndNoDocuments =
			!cluster_dom && document_dom && Object.keys(document_dom).length === 0;

		const isShannonFile =
			index === 'shannon' &&
			typeof activeDatabase === 'string' &&
			activeDatabase.includes('file-');
		const linkToShannonFile = isShannonFile
			? `/library/file/transcript/${activeDatabase.replace('file-', '')}`
			: '';

		const handleGoToShannonFile = () => {
			navigate(linkToShannonFile);
		};

		// RESETS & SETS DATA
		React.useEffect(() => {
			if (isSuccess) {
				setData([]);

				if (hasClustersAndDocuments) {
					const formattedData = formatD3ClusterData(
						cluster_dom.groups,
						document_dom,
						query
					);
					setData(formattedData);
				}
			}
		}, [cluster_dom, document_dom, setData, isSuccess, hasClustersAndDocuments, query]);

		// USES DATA and builds member docs
		React.useEffect(() => {
			const hasData = data.length > 0;

			if (hasData && isSuccess) {
				const memberDocs = buildAllMemberDocs(getFilteredData(data, basket));

				setSelectedMemberDocs(memberDocs);
			}
		}, [basket, setSelectedMemberDocs, data, isSuccess, hasClustersAndDocuments]);

		// USES DATA and resets & renders ClusterView
		React.useEffect(() => {
			const hasData = data.length > 0;

			if (hasData && hasClustersAndDocuments && !isLoading) {
				resetClusterView(svgRef);
				renderClusterView(data, svgRef, query);
			}
		}, [data, hasClustersAndDocuments, query, isSuccess, isLoading]);

		useImperativeHandle(ref, () => ({
			recluster: (action) => {
				if (action === 'zoomIn') {
					zoomInController(Client);
				} else {
					zoomOutController(Client);
				}
			}
		}));

		React.useEffect(() => {
			// TODO: differentiate unauthorized reasons.
			// e.x. token expired, token invalid, sigature invalid
			if (isUnauthorized) {
				navigate('/signout/expired');
			}
		}, [isUnauthorized, navigate]);

		return (
			<div data-component="ClustersView">
				<ClusterSVGSprites />

				{hasClustersAndDocuments ? (
					<>
						<div className="cluster-visualization" ref={svgRef} />
						{isShannonFile && (
							<div className="transcript-link">
								<Button
									size="sm"
									variant="outline"
									label="Go to full transcript"
									onClick={handleGoToShannonFile}
									iconRight="ArrowRight"
								/>
							</div>
						)}
					</>
				) : hasDocumentsNoClusters ? (
					<CenterContent>
						<Text variant="bodySM" fontStyle="italic" color="grey600">
							No clusters to show.{' '}
							{isShannonFile && (
								<RouterLink
									to={linkToShannonFile}
									label="Go to full transcript →"
								/>
							)}
						</Text>
					</CenterContent>
				) : hasNoClustersAndNoDocuments ? (
					<CenterContent>
						<Text variant="bodySM" fontStyle="italic" color="grey600">
							No results found.{' '}
							{isShannonFile && (
								<RouterLink
									to={linkToShannonFile}
									label="Go to full transcript →"
								/>
							)}
						</Text>
					</CenterContent>
				) : isErrorNoResults ? (
					<CenterContent>
						<Text variant="bodySM" fontStyle="italic" color="grey600">
							No results found.
						</Text>
					</CenterContent>
				) : isError || isUnreachable ? (
					<CenterContent>
						<div className="cluster-inline-error">
							<InlineAlert variant="error">
								{userMessage ? (
									<>
										{userMessageTitle && (
											<Text variant="bodySM" isBold>
												{userMessageTitle}.{' '}
											</Text>
										)}
										<Text>{formatContactUs(userMessage)}</Text>
									</>
								) : (
									<>
										<Text variant="bodySM" isBold>
											We couldn&apos;t process your request.{' '}
										</Text>
										<Text>
											This may be due to a technical issue on our end. If this
											issue persists,{' '}
											<Link
												label="contact us"
												href="mailto:support@cymantix.com"
											/>
										</Text>
									</>
								)}
							</InlineAlert>
						</div>
					</CenterContent>
				) : null}

				{isLoading && (
					<div className="loader-overlay">
						<CenterContent>
							<div className="loader-container">
								<BeatLoader size={15} color="#3E5CE0" loading />
							</div>
						</CenterContent>
					</div>
				)}
			</div>
		);
	}
);

const mapStateToProps = (state) => {
	return {
		query: state.pub.q,
		searchResults: state.sub,
		index: state.pub.index,
		activeDatabase: state.databases.activeDatabase,
		lens: state.pub.lens,
		depth: state.cluster_vis.depth,
		data: state.cluster_vis.data,
		basket: state.cluster_vis.basket,
		isSignedIn: state.user.signedIn
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		cacheClusterVis: (svg) => {
			dispatch(stateActions.cacheClusterVis(svg));
		},
		increaseDepth: () => {
			dispatch(stateActions.increaseDepth());
		},
		decreaseDepth: () => {
			dispatch(stateActions.decreaseDepth());
		},
		setData: (data) => {
			dispatch(stateActions.setData(data));
		},
		setSelectedMemberDocs: (data) => {
			dispatch(stateActions.setSelectedMemberDocs(data));
		},
		setUserData: (userData) => dispatch(stateActions.setUserData(userData))
	};
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
	ClusterView
);
