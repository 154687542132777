export type StatusObj = {
	isLoading: boolean;
	isSuccess: boolean;
	isError: boolean;
	isUnreachable: boolean;
	isErrorNoResults: boolean;
	isUnauthorized: boolean;
	isNotFound: boolean;
};

export function getStatus(status: number): StatusObj {
	const isLoading = status === 0;
	const isSuccess = status >= 200 && status < 300;
	const isUnreachable = status >= 400 && status < 500;
	const isError = status >= 500 && status < 600;
	const isErrorNoResults = status === 522;
	const isUnauthorized = status === 401;
	const isNotFound = status === 404;

	return {
		isLoading,
		isSuccess,
		isError,
		isUnreachable,
		isErrorNoResults,
		isUnauthorized,
		isNotFound
	};
}
