import { Outlet } from 'react-router-dom';
import { LeftSidebar } from '../../components/LeftSidebar/LeftSidebar';
import { container, content } from './LeftSidebarPageLayout.styles';

const LeftSidebarPageLayout = () => (
	<div className={container}>
		<LeftSidebar />
		<div className={content}>
			<Outlet />
		</div>
	</div>
);

export default LeftSidebarPageLayout;
