import { leftSidebarListHeader } from './LeftSidebar.styles';
import { Text } from 'cymantic-ui';

type LeftSidebarListHeaderProps = {
	label: string;
};

export const LeftSidebarListHeader = ({ label }: LeftSidebarListHeaderProps) => (
	<div className={leftSidebarListHeader}>
		<Text tag="span" variant="headerPretitle3" textTransform="uppercase" color="grey500">
			{label}
		</Text>
	</div>
);
