import { css } from '@emotion/css';
import { FolderLinkVariant } from './FolderLink.definitions';
import { theme } from 'cymantic-ui/dist/theme';

type FolderLinkStyles = {
	root: string;
	icon: string;
	iconVariant: Record<FolderLinkVariant, string>;
};

export const folderLink: FolderLinkStyles = {
	root: css`
		display: flex;
		align-items: center;
		gap: ${theme.space.space8};
		padding: ${theme.space.space8};
		padding-right: ${theme.space.space16};
		border-radius: ${theme.borderRadius.radiusSM};
		border: 1px solid ${theme.color.grey300};
		background-color: ${theme.color.white};
		transition: background-color 0.2s ease-in-out;

		&:hover {
			background-color: ${theme.color.grey100};
		}
	`,
	icon: css`
		padding: ${theme.space.space8};
		border-radius: 3rem;
	`,
	iconVariant: {
		primary: css`
			color: ${theme.color.primary800};
			background-color: ${theme.color.primary100};
		`,
		secondary: css`
			color: ${theme.color.grey800};
			background-color: ${theme.color.grey100};
		`
	}
};
