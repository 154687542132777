import React from 'react';
import { Text } from 'cymantic-ui';

import '../../css/Relativity.css';

const Relativity = ({ color, label, border }) => (
	<div className="relativity">
		<div className="color-swatch" style={{ background: `${color}`, border: `${border}` }} />
		<Text tag="span" variant="bodyTiny">
			{label}
		</Text>
	</div>
);

export default Relativity;
