import {
	clearBasket,
	clearDepth,
	decreaseDepth,
	increaseDepth
} from '../../../redux/stateActions.js';
import store from '../../../store';
import { getScoredDocsWrapper } from './ClusterViewHelpers.js';

/**
 * Zooms in on the current cluster visualization by sending selected documents to the server for re-clustering.
 *
 * @param {Object} Client - The client object used to communicate with the server.
 * @returns {Promise} - A Promise that resolves when the re-clustering is complete.
 */
export async function zoomInController(Client) {
	const docs = getScoredDocsWrapper();
	const { isSuccess } = await Client.basket_cluster(docs);

	if (isSuccess) {
		store.dispatch(increaseDepth());
		store.dispatch(clearBasket());
	} else {
		throw new Error('Error in zoomInController()');
	}
}

/**
 * Zooms out on the current cluster visualization by requesting a re-clustering from the server with previously selected documents.
 *
 * @param {Object} Client - The client object used to communicate with the server.
 * @returns {Promise} - A Promise that resolves when the re-clustering is complete.
 */
export async function zoomOutController(Client) {
	const current_depth = store.getState().cluster_vis.depth;

	if (current_depth <= 2) {
		store.dispatch(clearBasket());
		const { isSuccess } = await Client.query_cluster();

		if (isSuccess) {
			store.dispatch(clearDepth());
			store.dispatch(clearBasket());
		} else {
			throw new Error(`Error in zoomOutController at depth: ${current_depth}`);
		}
	} else {
		// If the depth is greater than 2, get the previously selected documents and send them to the server for re-clustering
		const cached_vis = store.getState().cluster_vis.cachedVis;
		const previous_docs_object = cached_vis[current_depth - 2];

		store.dispatch(clearBasket());
		const { isSuccess } = await Client.basket_cluster(previous_docs_object);

		if (isSuccess) {
			store.dispatch(decreaseDepth());
			store.dispatch(clearBasket());
		} else {
			throw new Error('Error in zoomInController()');
		}
	}
}
