import { css } from '@emotion/css';
import { theme, mq } from 'cymantic-ui';

export const container = css`
	display: flex;
	flex-direction: column;
	gap: ${theme.space.space48};

	${mq.tabletLarge} {
		padding: ${theme.space.space32};
	}
`;

export const subContainer = css`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: ${theme.space.space16};
`;
