export const getShannonLink = () => {
	const location = window.location.href;
	const isLocalhost = location.includes('localhost');
	const isDev = location.includes('dev');
	const isTest = location.includes('test');

	if (isLocalhost || isDev) {
		return 'https://dev-shannon.cymantix.com/';
	}

	if (isTest) {
		return 'https://test-shannon.cymantix.com/';
	}

	return 'https://shannon.cymantix.com/';
};
