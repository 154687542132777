import Cookies from 'js-cookie';

let initialState = {
	user: {
		email: Cookies.get('cymantix.email') || '',
		pass: '',
		hash: '',
		remember: Cookies.get('cymantix.remember') || false
	},
	// TODO combine or get rid of these various states...
	userData: {
		name: '',
		email: ''
	},
	newUser: {
		firstName: '',
		lastName: '',
		email: '',
		pass: '',
		passRetype: '',
		agree: false,
		hash: ''
	},
	userId: 0
};

const user = (state = initialState, action) => {
	switch (action.type) {
		// User holds state that determine if a user is signed in and what their identity is
		case 'SET_USER': {
			const user = action.payload.data;

			const newState = Object.assign({}, { ...state }, { user });
			return newState;
		}
		case 'SET_USER_DATA': {
			const userData = action.payload.data;

			const newState = Object.assign({}, { ...state }, { userData });
			return newState;
		}
		case 'SET_NEW_USER': {
			const newUser = action.payload.data;

			const newState = Object.assign({}, { ...state }, { newUser });
			return newState;
		}
		case 'SET_USER_ID': {
			const userId = action.payload.data;
			const newState = Object.assign({}, { ...state }, { userId });
			return newState;
		}
		default:
			return state;
	}
};

export default user;
