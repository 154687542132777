import * as stateActions from './redux/stateActions';
import Cookies from 'js-cookie';
import { getStatus } from './utilities/get-status';
import { generateRequestSignature } from './sign';
import { getDomain } from './components/Auth/CognitoHelpers';
import { isLocalHost } from './utilities/is-local-host';
import { logError } from './utilities/logger';

const host = isLocalHost() ? '' : process.env.REACT_APP_API_HOST;

let store;
export default class Client {
	/**
	 * @param  {Object} data
	 * @param  {Object} data.store - The Redux store.
	 */
	static init(data) {
		store = data.store;
	}

	/**
	 * Sends a cluster request to the server with either a text query or a list of scored documents and returns the response as a promise.
	 *
	 * @async
	 * @param {Object} clusterBody - The cluster request body.
	 * @param {string} clusterBody.query - The text query to cluster. If provided, scored_doc_ids must be null.
	 * @param {Object} clusterBody.scored_doc_ids - The scored documents to cluster. If provided, query must be null.
	 * @param {string} clusterBody.index - The index to cluster.
	 * @param {string} clusterBody.lens - The lenses to apply to the cluster results.
	 * @returns {Promise} - A Promise that resolves to the cluster results data.
	 */
	async cluster(clusterBody) {
		const signature = await generateRequestSignature();

		let body;

		if (clusterBody.query || clusterBody.query === '') {
			body = {
				query: clusterBody.query,
				index: clusterBody.index,
				lens: clusterBody.lens,
				file: clusterBody.file
			};
		} else {
			body = {
				scored_doc_ids: clusterBody.scored_doc_ids,
				index: clusterBody.index,
				lens: clusterBody.lens
			};
		}

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-RUTHI-Authorization': `Bearer ${Cookies.get('idToken')}`,
				'X-RUTHI-Request-ID': signature.request_id,
				'X-RUTHI-Request-Token': signature.token
			},
			body: JSON.stringify(body)
		};

		const response = await fetch(`${host}/api/cluster`, requestOptions);

		return response;
	}

	/**
	 * Sends a search request with the given query, index, and lenses,
	 * and stores the search request in the state.
	 *
	 * @async
	 * @param {Object} payload - The search request payload containing the query, index, and lenses. Not passed from results page resulting in depth clearing.
	 * @param {string} payload.query - The query to search for.
	 * @param {string} payload.index - The index to search.
	 * @param {string} payload.lens - The lenses to apply to the search results.
	 * @returns {Promise} - A Promise that resolves to the search results data.
	 */
	async query_cluster(payload) {
		let clusterBody;
		if (payload === undefined) {
			store.dispatch(stateActions.setLoading());
			store.dispatch(stateActions.clearDepth());
			const searchInfo = store.getState().pub;
			clusterBody = {
				query: searchInfo.q,
				index: searchInfo.index,
				lens: searchInfo.lens,
				file: searchInfo.file
			};
		} else {
			clusterBody = payload;
		}

		const response = await this.cluster(clusterBody);
		const data = await response.json();
		const { isSuccess } = getStatus(response.status);

		if (isSuccess) {
			if (payload === undefined) {
				store.dispatch(stateActions.clearBasket());
				store.dispatch(stateActions.clearCacheVis());
				store.dispatch(stateActions.clearSelectedMemberDocs());
			} else {
				store.dispatch(stateActions.clearData());
			}
			store.dispatch(stateActions.setDocCount(Object.entries(data.document_dom).length));
			this.storeResults({ ...data, code: response.status, userMessage: '' });
		} else {
			this.storeResults({
				...data,
				code: response.status,
				userMessageTitle: data.user_message_title,
				userMessage: data.user_message
			});
		}

		if (payload === undefined) {
			return { isSuccess };
		} else {
			return data;
		}
	}

	/**
	 * Sends a search request with the given query, index, and lenses,
	 * and stores the search request in the state.
	 *
	 * @async
	 * @param {Object} scored_doc_ids - The scored documents to cluster.
	 * @returns {Promise} - A Promise that resolves to the search results data.
	 */
	async basket_cluster(scored_doc_ids) {
		store.dispatch(stateActions.setLoading());

		const index = store.getState().pub.index;
		const lens = store.getState().pub.lens;

		const clusterBody = {
			scored_doc_ids: scored_doc_ids,
			index: index,
			lens: lens
		};

		const response = await this.cluster(clusterBody);
		const data = await response.json();
		const { isSuccess } = getStatus(response.status);

		if (isSuccess) {
			store.dispatch(stateActions.cacheClusterVis(scored_doc_ids));
			store.dispatch(stateActions.clearBasket());
			store.dispatch(stateActions.clearSelectedMemberDocs());
			store.dispatch(stateActions.setDocCount(Object.entries(data.document_dom).length));

			this.storeResults({ ...data, code: response.status });
		} else {
			this.storeResults({
				...data,
				code: response.status,
				userMessageTitle: data.user_message_title,
				userMessage: data.user_message
			});
		}

		return { isSuccess };
	}

	/**
	 * Retrieves mapping of databases a user can search
	 *
	 * @async
	 * @returns {Promise} - A Promise that resolves to the new database map
	 */
	async getDatabaseMap() {
		const signature = await generateRequestSignature();
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'X-RUTHI-Authorization': `Bearer ${Cookies.get('idToken')}`,
				'X-RUTHI-Request-ID': signature.request_id,
				'X-RUTHI-Request-Token': signature.token
			}
		};

		const response = await fetch(`${host}/api/databases`, requestOptions);
		const data = await response.json();

		const statusObj = getStatus(response.status);

		return { data, statusObj };
	}

	/**
	 * Retrieves mapping of lens a user can search for a selected databse (index)
	 *
	 * @async
	 * @returns {Promise} - A Promise that resolves to the new lens map
	 */
	async getLensMap(index) {
		let body = { index: '' };
		if (index) {
			body.index = index;
		}
		const signature = await generateRequestSignature();
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-RUTHI-Authorization': `Bearer ${Cookies.get('idToken')}`,
				'X-RUTHI-Request-ID': signature.request_id,
				'X-RUTHI-Request-Token': signature.token
			},
			body: JSON.stringify(body)
		};

		const response = await fetch(`${host}/api/lenses`, requestOptions);
		const data = await response.json();

		const { isUnauthorized } = getStatus(response.status);

		return { data, isUnauthorized };
	}

	/**
	 * Retrieves the files associated with the authenticated user.
	 *
	 * @async
	 * @returns {Promise} - A Promise that resolves to the user's files data.
	 */
	async getFiles({ folderId, page }) {
		// Define the request options.
		const signature = await generateRequestSignature();
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'X-RUTHI-Authorization': `Bearer ${Cookies.get('idToken')}`,
				'X-RUTHI-Request-ID': signature.request_id,
				'X-RUTHI-Request-Token': signature.token
			}
		};

		try {
			// Send the GET request and wait for the response.
			let url = `${host}/api/getFiles`;
			if (page && folderId) {
				url = url.concat(`?page=${page}&folder=${folderId}`);
			} else if (page) {
				url = url.concat(`?page=${page}`);
			} else if (folderId) {
				url = url.concat(`?folder=${folderId}`);
			}

			const response = await fetch(url, requestOptions);

			// Parse the response data as JSON.
			const userFiles = await response.json();
			const statusObj = getStatus(response.status);

			// Update the state with the user's files data.
			store.dispatch(stateActions.setUserFiles(userFiles));

			// Return the user's files data.
			return { data: userFiles, statusObj };
		} catch (error) {
			logError('Error in client.getFiles()', error);

			return {
				data: null,
				statusObj: getStatus(500)
			};
		}
	}

	async getAllFolders() {
		const signature = await generateRequestSignature();
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'X-RUTHI-Authorization': `Bearer ${Cookies.get('idToken')}`,
				'X-RUTHI-Request-ID': signature.request_id,
				'X-RUTHI-Request-Token': signature.token
			}
		};

		try {
			// Send the GET request and wait for the response.
			const url = `${host}/api/getAllFolders`;
			const response = await fetch(url, requestOptions);
			const data = await response.json();
			const statusObj = getStatus(response.status);

			return { data, statusObj };
		} catch (error) {
			logError('Error in client.getAllFolders()', error);

			return {
				data: null,
				statusObj: getStatus(500)
			};
		}
	}

	async getFolderInfo(folderId) {
		// Define the request options.
		const signature = await generateRequestSignature();
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'X-RUTHI-Authorization': `Bearer ${Cookies.get('idToken')}`,
				'X-RUTHI-Request-ID': signature.request_id,
				'X-RUTHI-Request-Token': signature.token
			}
		};

		try {
			// Send the GET request and wait for the response.
			const url = folderId
				? `${host}/api/getFolderInfo/${folderId}`
				: `${host}/api/getFolderInfo`;
			const response = await fetch(url, requestOptions);
			const data = await response.json();
			const statusObj = getStatus(response.status);

			return { data, statusObj };
		} catch (error) {
			logError('Error in client.getFolderInfo()', error);

			return {
				data: null,
				statusObj: getStatus(500)
			};
		}
	}

	async getFile(fileId) {
		// Define the request options.
		const signature = await generateRequestSignature();
		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'X-RUTHI-Authorization': `Bearer ${Cookies.get('idToken')}`,
				'X-RUTHI-Request-ID': signature.request_id,
				'X-RUTHI-Request-Token': signature.token
			}
		};

		try {
			const url = `${host}/api/getFile/${fileId}`;
			const response = await fetch(url, requestOptions);
			const data = await response.json();
			const statusObj = getStatus(response.status);

			return { data, statusObj };
		} catch (error) {
			logError('Error in client.getFile()', error);

			return {
				data: null,
				statusObj: getStatus(500)
			};
		}
	}

	/**
	 * Check Cognito Token is valid
	 *
	 * @param {string} idToken - The JWT token to be checked.
	 * @returns bool - A boolean status on token validity.
	 */
	async checkCognitoToken(idToken) {
		// TODO : add error handling
		// TODO : check cognito jwt token to Cognito's endpoint
		const signature = await generateRequestSignature();

		const requestOptions = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'X-RUTHI-Authorization': `Bearer ${idToken}`,
				'X-RUTHI-Request-ID': signature.request_id,
				'X-RUTHI-Request-Token': signature.token
			}
		};

		const response = await fetch(`${host}/api/token`, requestOptions);

		const { isSuccess } = getStatus(response.status);

		if (isSuccess) {
			// This theoretically should only happen when the Cognito token is valid
			Cookies.set('hasSignedIn', 'true', getDomain());
		}

		return isSuccess;
	}

	/**
	 * @param  {Object} payload //Contains the results of a search
	 */
	async storeResults(payload) {
		store.dispatch(stateActions.searchResults(payload));

		if (!payload.code) {
			store.dispatch(stateActions.updateSimMat(payload.cluster_dom.similarityMatrix));
			store.dispatch(stateActions.avgSimMat());
		}
	}

	/**
	 * Summarizes the content of a document.
	 *
	 * @async
	 * @param {string} documentId - The uuid of the document.
	 * @param {string} index - The index the document is from.
	 * @param {string} lens - The lenses applied to the search results.
	 * @returns {Promise} - A Promise that resolves to the summary of the document.
	 */
	async getSummary(documentId, index, lens) {
		const signature = await generateRequestSignature();
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-RUTHI-Authorization': `Bearer ${Cookies.get('idToken')}`,
				'X-RUTHI-Request-ID': signature.request_id,
				'X-RUTHI-Request-Token': signature.token
			},
			body: JSON.stringify({
				document_id: documentId,
				index: index,
				lens: lens
			})
		};

		const response = await fetch(`${host}/api/summary`, requestOptions);
		const data = await response.json();

		return data;
	}
}
