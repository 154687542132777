import { Icon, Logo, Product, IconName, LinkBaseProps } from 'cymantic-ui';
import { NavLink, NavLinkProps } from 'react-router-dom';

import { leftSidebarLink } from './LeftSidebarLinks.styles';

type LeftSidebarLogoLinkProps = {
	product: Product;
	href: string;
};

export const LeftSidebarLogoLink = ({ href, product }: LeftSidebarLogoLinkProps) => (
	<a href={href} target="_blank" rel="noreferrer noopener" className={leftSidebarLink.root}>
		<Logo product={product} width="1rem" height="1rem" />
		<span>{product}</span>
	</a>
);

type LeftSidebarNavLinkProps = {
	label: string;
	icon: IconName;
} & NavLinkProps;

export const LeftSidebarNavLink = ({ label, icon, ...navLinkProps }: LeftSidebarNavLinkProps) => (
	<NavLink
		{...navLinkProps}
		className={({ isActive }) => (isActive ? leftSidebarLink.active : leftSidebarLink.root)}
	>
		<Icon icon={icon} />
		<span>{label}</span>
	</NavLink>
);

type LeftSidebarIconLinkProps = {
	icon: IconName;
} & LinkBaseProps;

export const LeftSidebarIconLink = ({ label, icon, href, isBlank }: LeftSidebarIconLinkProps) => (
	<a
		href={href}
		target={isBlank ? '_blank' : undefined}
		rel={isBlank ? 'noreferrer noopener' : undefined}
		className={leftSidebarLink.root}
	>
		<Icon icon={icon} />
		<span>{label}</span>
	</a>
);
