import { v4 as uuidv4 } from 'uuid';
import { SignJWT } from 'jose';

/**
 * Generate a new request id and corresponding JWT token to sign requests.
 * @returns {string, string} - Request ID and JWT token.
 */
export async function generateRequestSignature() {
	const secret = new TextEncoder().encode(process.env.REACT_APP_JWT_SECRET);

	// generate a random request id for signing
	const request_id = uuidv4();

	// generate a token with 1 min shelf life for request
	let content = {
		request_id: request_id
	};
	const token = await new SignJWT(content)
		.setProtectedHeader({ alg: 'HS256' })
		.setIssuedAt()
		.setExpirationTime('1m')
		.sign(secret);

	return {
		request_id: request_id,
		token: token
	};
}
