import * as React from 'react';
import { Text } from 'cymantic-ui';
import { shuffleHeader } from './ShuffleHeader.styles';

const headerMessages = [
	'See the big picture in your data.',
	'Deep dive into the sea of information.',
	'See the forest for the trees.'
];

const startMessage = Math.floor(Math.random() * 3);

export const ShuffleHeader = () => {
	const [messageIndex, setMessageIndex] = React.useState(startMessage);

	React.useEffect(() => {
		const timeout = setInterval(() => setMessageIndex(messageIndex + 1), 15000);

		return () => {
			clearTimeout(timeout);
		};
	}, [messageIndex]);

	const shuffleMessage = headerMessages[messageIndex % headerMessages.length];

	return (
		<div className={shuffleHeader}>
			<Text tag="h1" variant="headerH3" textAlign="center">
				{shuffleMessage}
			</Text>
			<Text tag="h2" color="grey600" textAlign="center">
				What would you like to explore?
			</Text>
		</div>
	);
};
