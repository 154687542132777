import * as d3 from 'd3';
import store from '../../../store';
import * as settings from './ClusterView.definitions';

/**
 * formatD3ClusterData - formats cluster data for D3 visualization
 * @param {Object} data - cluster_dom.groups object
 * @param {Object} document_dom - document data object
 * @param {string} query - The search query
 * @returns {Array} formatted array of cluster data
 * @note Sorting determines which items are in the middle of the graph
 */
export function formatD3ClusterData(data, document_dom, query) {
	// Map cluster data with key, sizeValue, and memberDocs
	const mappedData = Object.entries(data).map(([key, values]) => {
		return {
			...values,
			key,
			sizeValue: values.memberDocs.length
		};
	});

	// Calculate relativity scores and add colorValue property
	const dataWithScores = calculateRelativity(mappedData, document_dom, query)
		.map((item) => ({
			...item,
			colorValue: item.score
		}))
		.sort((a, b) => b.colorValue - a.colorValue);

	return dataWithScores;
}

/**
 * getMinMaxArrValues
 * @param arr: Array of numbers
 * @returns: { minSize, maxSize }
 */
export function getMinMaxArrValues(arr) {
	const minSize = Math.min.apply(null, arr);
	const maxSize = Math.max.apply(null, arr);

	return { minSize, maxSize };
}

/**
 * createD3BubbleScaler
 * @param {*} minSize: smallest cluster size
 * @param {*} maxSize: largest cluster size
 * @returns: { getBubbleScale } function that is used for formatting data to set up the bubble chart root, determines how bubbles are scaled
 * @note .scaleLinear also looks good
 */
export function createD3BubbleScaler(minSize, maxSize) {
	const getBubbleScale = d3
		.scaleLog()
		.domain([minSize, maxSize])
		.range([settings.minimumBubbleSize, settings.maximumBubbleSize]);

	return { getBubbleScale };
}

/**
 * calculateRelativity
 *
 * Calculates the "relativity" of each cluster, which is a measure of how
 * representative a cluster is of the larger dataset. This is used to color
 * the clusters in the visualization.
 *
 * @param {Array} newData - Array of cluster data.
 * @param {Object} document_dom - Object containing metadata for all documents in the dataset.
 * @returns {Array} - Array of cluster data with added "score" property.
 */
export function calculateRelativity(newData, document_dom, query) {
	// If query is empty, return data with 100% relativity for each cluster
	if (!query) {
		return newData.map((item) => ({
			...item,
			score: 100,
			zScore: 1
		}));
	}

	// Store all z-scores to calculate min/max
	const running_scores = [];

	// Calculate standard deviation and mean of member document scores for all clusters
	const scores = newData.flatMap((cluster) => cluster.memberDocs.map((doc) => doc.score));
	const mean = scores.reduce((acc, score) => acc + score, 0) / scores.length;
	const std = Math.sqrt(
		scores.reduce((acc, score) => acc + (score - mean) ** 2, 0) / scores.length
	);

	// Calculate z-score for each cluster based on the average score of its member documents
	newData.forEach((cluster) => {
		const totalScore = cluster.memberDocs.reduce((acc, doc) => acc + doc.score, 0);
		const avgScore = totalScore / cluster.memberDocs.length;
		const zScore = (avgScore - mean) / std;
		cluster.zScore = zScore;
		running_scores.push(zScore);
	});

	// Calculate "score" for each cluster based on z-scores, and store in "score" property
	const minZScore = Math.min(...running_scores);
	const maxZScore = Math.max(...running_scores);
	newData.forEach((d) => {
		d.score = ((d.zScore - minZScore) / (maxZScore - minZScore)) * 100;
	});

	return newData;
}

/**
 * resetClusterView: Removes SVG, tooltip & resets data
 * @param {*} svgRef
 */
export function resetClusterView(svgRef) {
	d3.select(svgRef.current).selectAll('svg').remove();
	d3.select(`${settings.containerClass} .tooltip`).remove();
}

/**
 * Gets filtered data based on the current state of basket from the store.
 *
 * @returns {Array} Filtered data.
 */
export function getFilteredData(data = false, basket = false) {
	if (!data && !basket) {
		data = store.getState().cluster_vis.data;
		basket = store.getState().cluster_vis.basket;
	}

	// Filter data based on the current state of basket.
	const filtered = data.filter((obj, idx, arr) => {
		const nodeKey = arr[idx].key;
		return basket.includes(parseInt(nodeKey));
	});
	return filtered;
}

/**
 * Build an array of unique member document IDs from the filtered data.
 *
 * @param {Array} filteredData - The filtered data to extract member document IDs from.
 *
 * @returns {Array} An array of unique member document IDs.
 */
export function buildAllMemberDocs(filteredData) {
	const allMemberDocs = [];

	filteredData.forEach((item) => {
		item.memberDocs.forEach((member) => {
			if (!allMemberDocs.includes(member)) {
				allMemberDocs.push(member);
			}
		});
	});

	return allMemberDocs;
}

/**
 * Given an array of member document IDs and a document DOM, return an object of
 * selected member documents.
 *
 * @param {Array} allMemberDocs - An array of objects with uuid and score.
 * @param {Object} document_dom - The document DOM to extract the member documents from.
 *
 * @returns {Object} An object containing selected member documents indexed by ID.
 */
export function getSelectedMemberDocs(allMemberDocs) {
	const docs = {};
	const document_dom = store.getState().sub.search.document_dom;

	allMemberDocs.forEach((item) => {
		const uuid = item.uuid;
		if (document_dom[uuid]) {
			docs[uuid] = document_dom[uuid];
		}
	});

	return docs;
}

/**
 * Returns an array of documents keyed by selected nodes for the cluster view.
 * @returns {Object} The selected member documents.
 */
export function getDocsWrapper() {
	const allMemberDocs = store.getState().cluster_vis.selectedMemberDocs;
	const selectedMemberDocs = getSelectedMemberDocs(allMemberDocs);
	return selectedMemberDocs;
}

/**
 * Returns an array of scored documents keyed by selected nodes for the cluster view.
 * @returns {Object} The selected member documents.
 */
export function getScoredDocsWrapper() {
	return store.getState().cluster_vis.selectedMemberDocs;
}
