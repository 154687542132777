import React from 'react';
import { Link, Text, Icon, theme } from 'cymantic-ui';
import { BeatLoader } from 'react-spinners';
import Client from '../../../client';

const getSummary = async (uuid, databaseIndex, lens) => {
	const client = new Client();
	const summaryData = await client.getSummary(uuid, databaseIndex, lens);
	return summaryData;
};

export const DocRow = ({
	data,
	dataMap,
	searchInfo,
	lazyLoadRowIndex,
	lazyLoadStyle,
	setLazyLoadRowHeight
}) => {
	const lazyLoadRowRef = React.useRef({});

	const { title, src, author, date } = dataMap;
	const { index: databaseIndex, lens: lens } = searchInfo;
	const client = new Client();

	React.useEffect(() => {
		if (databaseIndex === 'shannon') {
			if (summaryData === null) {
				getSummary(data.uuid, databaseIndex, lens).then((fetchedSummaryData) => {
					setSummaryData(fetchedSummaryData);
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [isLoading, setIsLoading] = React.useState(false);
	const [isExpanded, setIsExpanded] = React.useState(databaseIndex === 'shannon');
	const [summaryData, setSummaryData] = React.useState(null);
	// Sets RowHeight on initial render
	// Sets RowHeight on toggle of isExpanded and isReadMoreExpanded
	const hasData = summaryData !== null;
	React.useEffect(() => {
		setLazyLoadRowHeight(
			lazyLoadRowIndex,
			lazyLoadRowRef.current.getBoundingClientRect().height
		);
	}, [isExpanded, setLazyLoadRowHeight, lazyLoadRowIndex, lazyLoadRowRef, hasData]);

	// Since we may be presenting the actual content and not a summary, we should change "Summary: " to something else or just remove it
	return (
		<div style={lazyLoadStyle}>
			<div ref={lazyLoadRowRef} data-component="DocChip">
				<div className="doc-info">
					<Link label={data[title]} href={data[src]} isBlank />
					<Text variant="bodyXS" tag="div">
						{Array.isArray(data[author]) ? data[author].join(', ') : data[author]}
					</Text>
					<Text variant="bodyXXS" color="grey600" tag="div">
						{data[date]}
					</Text>

					{isExpanded && summaryData && (
						<p data-component="DocSummary">
							<Text tag="span" variant="bodyXXS">
								<Text tag="span" variant="bodyXXS">
									{summaryData.summary}
								</Text>
							</Text>
						</p>
					)}
				</div>
				<button
					onClick={async () => {
						setIsLoading(true);
						if (!isExpanded && summaryData === null) {
							const fetchedSummaryData = await client.getSummary(
								data.uuid,
								databaseIndex,
								lens
							);
							setSummaryData(fetchedSummaryData);
						}
						setIsExpanded(!isExpanded);
						setIsLoading(false);
					}}
					aria-label="Toggle Document Summary"
				>
					{isLoading ? (
						<BeatLoader size={6} color={theme.color.primary400} />
					) : (
						<Icon icon={isExpanded ? 'ChevronUp' : 'ChevronDown'} size="space20" />
					)}
				</button>
			</div>
		</div>
	);
};

DocRow.displayName = 'Doc Row';

export default DocRow;
