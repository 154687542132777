import { css } from '@emotion/css';
import { theme, mq } from 'cymantic-ui';

export const searchSection = css`
	display: flex;
	flex-direction: column;
	gap: ${theme.space.space12};
	align-items: center;
`;

export const searchForm = css`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: ${theme.space.space16};

	${mq.tabletSmall} {
		flex-wrap: nowrap;
		flex-direction: row;
		gap: ${theme.space.space8};
	}
`;
