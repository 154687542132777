import React from 'react';
import { connect } from 'react-redux';
import { withContext } from '../../../withContext';
import { Text, Icon } from 'cymantic-ui';
import { motion, AnimatePresence } from 'framer-motion';

// Components
import DocList from './DocList';

const expandedWidth = '30rem';
const duration = 0.4;

const DocPanel = ({ isExpanded, handleClose, doc_count }) => {
	const numOfDocs = doc_count;

	return (
		<motion.div
			initial={false}
			animate={
				!isExpanded
					? { width: '0', minWidth: '0' }
					: { width: expandedWidth, minWidth: expandedWidth }
			}
			transition={{ duration }}
			style={{ overflow: 'hidden' }}
		>
			<AnimatePresence>
				{isExpanded && (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						style={{ width: expandedWidth, height: '100%' }}
						transition={{ duration }}
					>
						<div data-component="DocPanel">
							<div className="doc-panel-header">
								<Text tag="h3" variant="bodySM">
									Showing {numOfDocs} {numOfDocs !== 1 ? 'Results' : 'Result'}{' '}
								</Text>
								<button onClick={handleClose} aria-label="Close panel">
									<Icon icon="X" size="space24" color="grey500" />
								</button>
							</div>
							<DocList />
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</motion.div>
	);
};

const mapStateToProps = (state) => {
	const doc_count =
		state.cluster_vis.selectedMemberDocs.length > 0
			? state.cluster_vis.selectedMemberDocs.length
			: state.cluster_vis.totalDocCount;
	return { doc_count };
};

const DocPanelContainer = withContext(connect(mapStateToProps, null)(DocPanel));

export default DocPanelContainer;
