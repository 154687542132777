import { css } from '@emotion/css';
import { theme } from 'cymantic-ui/dist/theme';

/**
 * libraryTable styles
 * @note tds & ths create all the rounded corners and borders in this table
 * @reason trs DO NOT accept border-radius (even table overflow:hidden doesn't hide them), however tds & ths do
 */
export const libraryTable = css`
	border-collapse: separate;
	border-spacing: 0;
	width: 100%;

	td,
	th {
		border: solid 1px ${theme.color.grey300};
		border-left-style: none;
		border-top-style: none;
		border-right-style: none;

		// All bottom borders in table
		border-bottom-style: solid;

		padding: ${theme.space.space8};
	}

	th {
		// Top border of table
		border-top-style: solid;

		background: ${theme.color.grey100};
		color: ${theme.color.grey700};
		font-size: ${theme.fontSize.bodyXXS};
		text-align: left;
	}

	td {
		background-color: ${theme.color.white};
		color: ${theme.color.grey800};
		font-size: ${theme.fontSize.bodyXS};
	}

	tr:hover td {
		background: ${theme.color.grey100};
	}

	// Top-left corner
	th:first-child {
		border-top-left-radius: ${theme.borderRadius.radiusMD};
	}

	// Top-right corner
	th:last-child {
		border-top-right-radius: ${theme.borderRadius.radiusMD};
	}

	// Bottom-left corner
	tr:last-child td:first-child {
		border-bottom-left-radius: ${theme.borderRadius.radiusMD};
	}

	// Bottom-right corner
	tr:last-child td:last-child {
		border-bottom-right-radius: ${theme.borderRadius.radiusMD};
	}

	// Left border of table
	tr td:first-child,
	tr th:first-child {
		border-left-style: solid;
	}

	// Right border of table
	tr td:last-child,
	tr th:last-child {
		border-right-style: solid;
	}
`;

export const paginationWrapper = css`
	display: flex;
	justify-content: flex-end;
`;
