import isHotkey from 'is-hotkey';
import { TreeNode } from './Tree.definitions';

export const isNode = (t: string) => t === 'node';

/**
 * Gets the root node at `path []` from any node `path` in the tree
 *
 * @param node The current node to get the root node from
 * @returns `TreeNode`
 */
const getRootNode = (node: TreeNode): TreeNode => {
	return node.hasParent() && node.parent ? getRootNode(node.parent) : node;
};

/**
 *  Finds the root node from the current node and gets the power or highest node closest to
 *  the left
 *
 * @param node The current node to find the root node and start node from
 * @returns `TreeNode` | null
 */
const getStartNode = (node: TreeNode): TreeNode | null => {
	return getRootNode(node).children.first();
};

/**
 * Finds the root node from the current node and gets the base or lowest node closest to the right
 *
 * @param node The current node to find the root node and end node from
 * @returns `TreeNode` | null
 */
const getEndNode = (node: TreeNode): TreeNode | null => {
	const root = getRootNode(node);
	const lastRootChild = root.children.last();

	const getLastNode = (n: TreeNode): TreeNode => {
		// Reach next first, before children.
		let ret;
		const lastChild = node.children.last();

		if (n.hasNext() && n.next) {
			ret = getLastNode(n.next);
		} else if (n.hasChildren() && lastChild) {
			ret = getLastNode(lastChild);
		} else {
			ret = n;
		}

		return ret;
	};
	return lastRootChild ? getLastNode(lastRootChild) : null;
};

/**
 * Gets the base or lowest node of the closest node to the left of the current node
 *
 * @param node The current node to get the base node from
 * @returns `TreeNode`
 */
const getLeftBaseNode = (node: TreeNode): TreeNode => {
	const getBaseNode = (n: TreeNode): TreeNode => {
		if (isNode(n.type) && n.expanded()) {
			const lastChild = n.children.last();
			return n.hasChildren() && lastChild ? getBaseNode(lastChild) : n;
		}
		return n;
	};

	if (node.hasPrevious() && node.previous) {
		return getBaseNode(node.previous);
	}

	return node.hasParent() && node.parent ? node.parent : node;
};

/**
 * Gets the power or highest node of the closest node to the right of the current node
 *
 * @param node The current node to get the power node from
 * @returns `TreeNode`
 */
const getRightPowerNode = (node: TreeNode): TreeNode | null => {
	const getPowerNode = (n: TreeNode): TreeNode | null => {
		if (!n.hasNext()) {
			return n.hasParent() && n.parent ? getPowerNode(n.parent) : n;
		}

		return n.next;
	};

	if (isNode(node.type) && node.expanded()) {
		const firstChild = node.children.first();
		return node.hasChildren() && firstChild ? firstChild : node;
	}

	return getPowerNode(node);
};

/**
 * Expand the current node and all its adjacent nodes
 *
 * @param node The current node to expand all adjacent nodes for
 * @param prev An optional previous node of the current node which could be passed eagerly
 * @param next An optional next node of the current node which could be passed eagerly
 * @returns `void`
 */
const expandAdjacentNodes = (node: TreeNode, prev = node.previous, next = node.next): void => {
	[node, prev, next].forEach((n) => {
		if (n && isNode(n.type) && !n.expanded()) {
			n.expand();
		}
	});
	return !prev?.hasPrevious() && !next?.hasNext()
		? undefined
		: expandAdjacentNodes(node, prev?.previous, next?.next);
};

export const handleArrowDown = (evt: KeyboardEvent, node: TreeNode) => {
	const isArrowDown = isHotkey('arrowdown');
	if (isArrowDown(evt)) {
		const next = getRightPowerNode(node);

		if (next) {
			next.select();
		}
		evt.preventDefault();
	}
};

export const handleArrowUp = (evt: KeyboardEvent, node: TreeNode) => {
	const isArrowUp = isHotkey('arrowup');

	if (isArrowUp(evt)) {
		const previous = getLeftBaseNode(node);
		previous.select();
		evt.preventDefault();
	}
};

export const handleArrowLeft = (evt: KeyboardEvent, node: TreeNode) => {
	const isArrowLeft = isHotkey('arrowleft');

	if (!isArrowLeft(evt)) return;

	const selectParent = () => {
		// node.deselect()
		if (node.parent) {
			node.parent.select();
		}
	};

	if (isNode(node.type)) {
		if (node.expanded()) {
			node.collapse();
			return;
		}
	}

	if (node.hasParent()) selectParent();
};

export const handleArrowRight = (evt: KeyboardEvent, node: TreeNode) => {
	const isArrowRight = isHotkey('arrowright');
	if (!isArrowRight(evt)) return;

	const selectChild = () => {
		const firstChild = node.children.first();

		if (firstChild) {
			firstChild.select();
		}
	};

	if (isNode(node.type)) {
		if (!node.expanded()) {
			node.expand();
			return;
		}

		if (node.hasChildren()) selectChild();
	}
};

export const handleEnter = (evt: KeyboardEvent, node: TreeNode) => {
	const isSpacebar = isHotkey('spacebar');
	const isEnter = isHotkey('return');

	if (isSpacebar(evt) || isEnter(evt)) {
		if (node.expanded()) {
			node.collapse();
		} else {
			node.expand();
		}
	}
};

export const handleHome = (evt: KeyboardEvent, node: TreeNode) => {
	const isHome = isHotkey('home');

	if (isHome(evt)) {
		const startNode = getStartNode(node);

		if (startNode) {
			startNode.select();
		}
	}
};

export const handleEnd = (evt: KeyboardEvent, node: TreeNode) => {
	const isEnd = isHotkey('end');

	if (isEnd(evt)) {
		const endNode = getEndNode(node);

		if (endNode) {
			endNode.select();
		}
	}
};

export const handleAsterisk = (evt: KeyboardEvent, node: TreeNode) => {
	const isAsterisk = isHotkey('shift+8');

	if (isAsterisk(evt) && isNode(node.type)) {
		expandAdjacentNodes(node);
	}
};

export const Keyboard = {
	handleArrowDown,
	handleArrowLeft,
	handleArrowRight,
	handleArrowUp,
	handleAsterisk,
	handleEnd,
	handleEnter,
	handleHome
};
